import { useDispatch, useSelector } from "react-redux";
import { updateWelcomePopup } from "../store/slices/authSlice";
// import { Link } from "react-router-dom";

import verifyLogo from "../assets/imgs/verifylogo.png"
import { updateVerifyBtnClick } from "../store/slices/authSlice";

const Welcome = () => {
  const user = useSelector((state) => state.auth.userDetails);
  const dispatch = useDispatch();

  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

  function generateString(length) {
    let result = "";
    const charLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charLength));
    }

    return result;
  }
  const randomCharacters = generateString(50);

  return (
    <div className="welcome">
      <div className="welcome__page">
      <span className="welcome__page__close__icon" onClick={() => dispatch(updateWelcomePopup())}>
          <ion-icon name="close-outline"></ion-icon>
        </span>
        <p className="welcome__page__name">
          Welcome, <span className="name">{user.first_name}</span>
        </p>
        <p className="message">
          We're excited to have you, and we’re here to make your shopping
          experience unforgettable!
        </p>
        <i className="verify__account">
          Kindly verify your account to activate your wallet for seamless
          shopping.
        </i>
        <div className="welcome__btns">
          <button
            onClick={() => dispatch(updateWelcomePopup())}
            className="close__welcome"
          >
            Skip
          </button>
          <div className="verify-btn" onClick={() => {
            dispatch(updateVerifyBtnClick(true));
            dispatch(updateWelcomePopup());
            }}>
          <div className="verify-btn__logo">
        <img src={verifyLogo} alt="verify" />
        </div>
        <button className="verify-btn__text">Verify Account</button>
        </div>
          {/* <mati-button
            clientid="642a8eae9c002d001a503ede"
            flowId="64e72ce350ec62001bd20f99"
            metadata={`{"email": "${user.email}", "id": "${user.slug}", "hash": "${randomCharacters}"}`}
            color="#314706"
          /> */}
        </div>
      </div>
    </div>
  );
};

export default Welcome;
