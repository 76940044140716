import { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { baseUrl } from "../libries";

import axios from "axios";

import { login } from "../store/slices/authSlice";
import { addCartItem, clearCarts } from "../store/slices/cartSlice";

// images
import logo from "../assets/new-imgs/shopnig-logoo.svg";
import whatsapp from "../assets/new-imgs/whatsapp.png";
import sms from "../assets/new-imgs/sms.png";
import useAlert from "../hooks/useAlert";
import { isNumber } from "../utils/func";
import logodark from "../assets/new-imgs/shopnig-darklogo.svg";
import validator from "validator";
import { updateCodeSend } from "../store/slices/appSlice";

const SignupPopup = ({
  change,
  closePopup,
  setClosePopup,
  myCurrentLocation,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const signUpRef = useRef();
  const uiMode = useSelector((state) => state.app.uiMode);
  const carts = useSelector((state) => state.cart.carts);
  const codeSend = useSelector((state) => state.app.codeSend);

  const [userDetails, setUserDetails] = useState({
    name: "",
    email: "",
    phone_number: "",
    password: "",
  });

  const [code, setCode] = useState("");

  const [resendBlocked, setResendBlocked] = useState(0);
  const [resendBlockedInterval, setResendBlockedInterval] = useState(null);
  const [resendBlockedFor, setResendBlockedFor] = useState("");

  // Type
  const [type, ] = useState("email");

  // state to show password or hide password
  const [showPassword, setShowPassword] = useState(false);
  const [passwordValidations, setPasswordValidations] = useState([
    false,
    false,
    false,
    false,
    false,
  ]);

  useEffect(() => {
    const newPasswordValidations = [false, false, false, false, false];

    newPasswordValidations[0] = userDetails.password.length >= 8;

    newPasswordValidations[1] = userDetails.password
      .split("")
      .some((c) => "abcdefghijklmnopqrstuvwxyz".split("").includes(c));

    newPasswordValidations[2] = userDetails.password
      .split("")
      .some((c) =>
        "abcdefghijklmnopqrstuvwxyz".toUpperCase().split("").includes(c)
      );

    newPasswordValidations[3] = userDetails.password
      .split("")
      .some((c) => "1234567890".split("").includes(c));

    newPasswordValidations[4] = userDetails.password
      .split("")
      .some((c) => "~`@#$%^&*()-_=+{}[];:'\",.<>/?|\\/".split("").includes(c));

    setPasswordValidations(newPasswordValidations);
  }, [userDetails.password]);

  // sms type
  const [smsType, setSmsType] = useState("whatsapp");

  const [message, setMessage, clearMessage] = useAlert();

  const runValidations = () => {
    if (userDetails.name.length < 4) {
      setMessage("warning", "Name should be at least 4 characters");
      return false;
    }

    if (!validator.isEmail(userDetails.email)) {
      setMessage("warning", "Enter a valid email address");
      return false;
    }

    if (userDetails.password.length < 8 || userDetails.password.length > 16) {
      setMessage("warning", "Password should be 8 to 16 characters");
      return false;
    }

    return true;
  };

  const requestCode = (e) => {
    const target = e.target;

    if (!runValidations()) return;

    target.innerHTML = `<span class="fas fa-spinner fa-spin"></span>`;
    target.setAttribute("disabled", "disabled");

    axios({
      url: `${baseUrl}/register-otp`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify({
        mode: type,
        name: userDetails.name,
        [type]: `${type === "phone_number" ? "+234" : ""}${userDetails[type]}`,
      }),
    })
      .then((res) => {
        if (!res.data.status) {
          setMessage("warning", res.data.message);
        } else {
          setMessage("success", res.data.data.message);

          dispatch(
            updateCodeSend({
              key: userDetails.email,
              value: (Date.now() + 120000).toString(),
            })
          );
        }
      })
      .catch((err) => {
        if (err.code === "ERR_BAD_REQUEST") {
          setMessage("warning", err.response.data.message);
        } else {
          setMessage("error", err.message);
        }
      })
      .finally(() => {
        target.removeAttribute("disabled");
        target.innerHTML = `Resend Code`;
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!runValidations()) return;

    if (code.length !== 6)
      return setMessage("warning", "Code should be 6 characters long");

    signUpRef.current.innerHTML = `<span class="fas fa-spinner fa-spin"></span> Loading..`;
    signUpRef.current.setAttribute("disabled", "disabled");

    let token;

    axios({
      url: `${baseUrl}/register`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify({
        mode: type,
        name: userDetails.name,
        [type]: `${type === "phone_number" ? "+234" : ""}${userDetails[type]}`,
        verification_code: code,
        password: userDetails.password,
        password_confirmation: userDetails.password,
      }),
    })
      .then((response) => {
        token = response.data.data.token.accessToken;

        // making a call to fetch user account
        return axios({
          url: `${baseUrl}/accounts`,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
      })
      .then(async (resp) => {
        const userProfile = resp.data.data.user;
        userProfile.verification = resp.data.data.verification;
        userProfile.wallet = resp.data.data.wallet;
        userProfile.bank = resp.data.data.bank;

        dispatch(
          login({
            accessToken: token,
            userDetails: userProfile,
          })
        );

        //first sending items in the cart to the backend and then refetching cart items
        if (carts.length) {
          let allVariation =
            carts?.map((cartItem) => {
              return cartItem.variation_info !== null
                ? {
                    product_id: cartItem.id,
                    variation: cartItem.variation_info?.map((variatn) => ({
                      id: variatn.id,
                      quantity: variatn.quantity,
                    })),
                  }
                : {
                    product_id: cartItem.id,
                    quantity: cartItem.quantity,
                  };
            }) || [];

          const resp_1 = await axios({
            url: `${baseUrl}/carts/add`,
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            data: JSON.stringify({ product_orders: allVariation }),
          });
          const cartResp = resp_1.data.message;
        }

        const resp_2 = await axios({
          url: `${baseUrl}/carts`,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        const allCarts = resp_2.data.data.cart;
        if (!allCarts) {
          dispatch(clearCarts());
        }
        if (allCarts) {
          allCarts.map((cart) => {
            let allVariation = [];

            if (cart.variation !== null) {
              cart.variation.forEach((cartVar) => {
                allVariation.push({
                  amount: cartVar.amount,
                  id: cartVar.combo_id,
                  quantity: cartVar.quantity,
                  total: Number(cartVar.amount * cartVar.quantity),
                  stock_count: cartVar.in_stock,
                  variations: cartVar.variations,
                });
              });
            }
            return dispatch(
              addCartItem({
                id: cart.product.id,
                name: cart.product.name,
                color: cart.product.color,
                quantity: cart.quantity,
                src: cart.images,
                brand: "",
                price: Number(cart.product.amount),
                total: Number(cart.product.amount) * cart.quantity,
                variation_info: cart.product.is_fixed ? null : allVariation,
                stock_count:
                  cart.product.is_fixed === 1
                    ? cart.product.stock_count
                    : cart.product.in_stock,
                weight: cart.product.weight,
              })
            );
          });
        }

        navigate(myCurrentLocation);
        setClosePopup(false);
      })
      .catch((err) => {
        if (err.code === "ERR_BAD_REQUEST") {
          setMessage("warning", err.response.data.message);
        } else {
          setMessage("error", err.message);
        }
      });
  };

  const handleChangeUserInputs = (e) => {
    const { name, value } = e.target;

    setUserDetails({
      ...userDetails,
      [name]: value,
    });
  };

  useEffect(() => {
    if (resendBlocked <= 0 && typeof resendBlockedInterval === "number") {
      setResendBlocked(0);
      window.clearInterval(resendBlockedInterval);
      return setResendBlockedInterval(null);
    }

    if (resendBlocked > 0 && resendBlockedInterval === null) {
      setResendBlockedInterval(
        window.setInterval(() => {
          setResendBlocked((b) => b - 1000);
        }, 1000)
      );
    }
  }, [resendBlocked, resendBlockedInterval]);

  useEffect(() => {
    let codeCheck = userDetails.email;

    if (codeCheck && codeSend[codeCheck]) {
      const resendBlockedUntil = new Date(+codeSend[codeCheck]).getTime();

      const currentDate = new Date().getTime();

      if (currentDate < resendBlockedUntil) {
        setResendBlocked(resendBlockedUntil - currentDate);
        setResendBlockedFor(codeCheck);
      }
    }
  }, [userDetails.email, codeSend, setResendBlocked]);

  useEffect(() => {
    clearMessage();
  }, [
    type,
    userDetails.name,
    userDetails.email,
    userDetails.phone_number,
    userDetails.password,
    code,
    clearMessage,
  ]);

  const activateBtn =
    userDetails.name &&
    userDetails[type] &&
    userDetails.password &&
    !passwordValidations.includes(false) &&
    code;

  let resendBlockedDate = null;

  if (resendBlocked) {
    const date = new Date(resendBlocked);
    resendBlockedDate = `${String(date.getMinutes()).padStart(2, "0")}:${String(
      date.getSeconds()
    ).padStart(2, "0")}`;
  }

  return (
    <div>
      {closePopup ? (
        <div className="show__popup__login">
          <form onSubmit={handleSubmit} className="form__auth">
            <div
              className={`${
                closePopup ? "show__auth__login" : "hide__auth__login"
              } auth__main log__popup`}
            >
              <div className="close__pop" onClick={() => setClosePopup(false)}>
                <ion-icon name="close"></ion-icon>
              </div>
              <div className="auth__img-block">
                <a href="/">
                  <img
                    src={uiMode === "light" ? logo : logodark}
                    alt=""
                    className={["auth__img", "cursor-pointer"].join(" ")}
                  />
                </a>
              </div>
              <div className="auth__card">
                <div className="auth__card-main">
                  <div className="auth__header">
                    <h3 className="auth__heading">Create an account</h3>
                  </div>
                  <div className="auth__form">
                    <div className="form-group">
                      <label className="form-label">Name</label>
                      <input
                        name="name"
                        type="text"
                        value={userDetails.name}
                        placeholder="Enter your name"
                        className="form-input form-input--1"
                        autoComplete="off"
                        onChange={handleChangeUserInputs}
                      />
                    </div>
                    {type === "email" ? (
                      <div className="form-group">
                        <label className="form-label">Email</label>
                        <input
                          name="email"
                          value={userDetails.email}
                          type="text"
                          placeholder="Enter your email"
                          className="form-input form-input--1"
                          autoComplete="off"
                          onChange={handleChangeUserInputs}
                        />
                      </div>
                    ) : (
                      <div className="form-group">
                        <label className="form-label">Phone Number</label>
                        <div className="form-input-group form-input-group--1">
                          <button type="button">+234</button>
                          <input
                            name="phone_number"
                            value={userDetails.phone_number}
                            type="text"
                            placeholder="Enter your phone number"
                            autoComplete="new-password"
                            onChange={(e) =>
                              e.target.value
                                ? isNumber(e.target.value)
                                  ? setUserDetails((uD) => ({
                                      ...uD,
                                      phone_number: +e.target.value,
                                    }))
                                  : null
                                : setUserDetails((uD) => ({
                                    ...uD,
                                    phone_number: "",
                                  }))
                            }
                            maxLength={10}
                          />
                        </div>
                      </div>
                    )}
                    <div className="form-group mb-medium">
                      <label className="form-label">Password</label>
                      <div className="input-group input-group--1">
                        <input
                          name="password"
                          type={showPassword ? "text" : "password"}
                          value={userDetails.password}
                          placeholder="Enter your password"
                          autoComplete="new-password"
                          onChange={handleChangeUserInputs}
                        />
                        <button
                          type="button"
                          onClick={() => {
                            setShowPassword(!showPassword);
                          }}
                        >
                          {showPassword ? (
                            <ion-icon name="eye-outline"></ion-icon>
                          ) : (
                            <ion-icon name="eye-off-outline"></ion-icon>
                          )}
                        </button>
                      </div>
                      <div className="form-requirements">
                        <div
                          className={`${
                            passwordValidations[0] && "activate__password"
                          } pass_activation`}
                        >
                          <ion-icon
                            name={
                              passwordValidations[0]
                                ? "checkmark-circle"
                                : "checkmark-circle-outline"
                            }
                          ></ion-icon>
                          <span className="pass__criteria">
                            At least 8 characters
                          </span>
                        </div>
                        <div
                          className={`${
                            passwordValidations[1] && "activate__password"
                          } pass_activation`}
                        >
                          <ion-icon
                            name={
                              passwordValidations[1]
                                ? "checkmark-circle"
                                : "checkmark-circle-outline"
                            }
                          ></ion-icon>
                          <span className="pass__criteria">
                            Lowercase letter (a-z)
                          </span>
                        </div>
                        <div
                          className={`${
                            passwordValidations[2] && "activate__password"
                          } pass_activation`}
                        >
                          <ion-icon
                            name={
                              passwordValidations[2]
                                ? "checkmark-circle"
                                : "checkmark-circle-outline"
                            }
                          ></ion-icon>
                          <span className="pass__criteria">
                            Uppercase letter (A-Z)
                          </span>
                        </div>
                        <div
                          className={`${
                            passwordValidations[3] && "activate__password"
                          } pass_activation`}
                        >
                          <ion-icon
                            name={
                              passwordValidations[3]
                                ? "checkmark-circle"
                                : "checkmark-circle-outline"
                            }
                          ></ion-icon>
                          <span className="pass__criteria">Number (0-9)</span>
                        </div>
                        <div
                          className={`${
                            passwordValidations[4] && "activate__password"
                          } pass_activation`}
                        >
                          <ion-icon
                            name={
                              passwordValidations[4]
                                ? "checkmark-circle"
                                : "checkmark-circle-outline"
                            }
                          ></ion-icon>
                          <span className="pass__criteria">
                            Special character (#,*)
                          </span>
                        </div>
                      </div>
                    </div>
                    {type === "phone_number" ? (
                      <div className="otp-medium mb-medium">
                        <h3 className="otp-medium__heading">Receive OTP Via</h3>
                        <div className="otp-medium__blocks">
                          <div className="otp-medium__block">
                            <img
                              src={whatsapp}
                              alt=""
                              className="otp-medium__block-img"
                            />
                            <p className="otp-medium__block-name">
                              Whatsapp (Instant)
                            </p>
                            <input
                              type="checkbox"
                              className="checkbox-switch"
                              checked={smsType === "whatsapp"}
                              onChange={(e) =>
                                setSmsType(
                                  e.target.checked ? "whatsapp" : "sms"
                                )
                              }
                            />
                          </div>
                          <div className="otp-medium__block">
                            <img
                              src={sms}
                              alt=""
                              className="otp-medium__block-img"
                            />
                            <p className="otp-medium__block-name">
                              SMS (0 - 5 MIN)
                            </p>
                            <input
                              type="checkbox"
                              className="checkbox-switch"
                              checked={smsType === "sms"}
                              onChange={(e) =>
                                setSmsType(
                                  e.target.checked ? "sms" : "whatsapp"
                                )
                              }
                            />
                          </div>
                        </div>
                      </div>
                    ) : null}
                    <div className="form-group">
                      <label>
                        {type === "email"
                          ? "Verification code"
                          : "Resend verification code via"}{" "}
                      </label>
                      <div className="form-input-group">
                        <input
                          type="text"
                          placeholder="Verification code"
                          autoComplete="new-password"
                          value={code}
                          onChange={(e) => setCode(e.target.value)}
                        />

                        {resendBlocked &&
                        resendBlockedFor === userDetails.email ? (
                          <button
                            type="button"
                            style={{ opacity: "0.7" }}
                            disabled
                          >
                            Resend in {resendBlockedDate}
                          </button>
                        ) : (
                          <button
                            onClick={requestCode}
                            disabled={!userDetails.name || !userDetails[type]}
                            type="button"
                          >
                            Request Code
                          </button>
                        )}
                      </div>
                    </div>
                    {message}
                    <button
                      ref={signUpRef}
                      type="submit"
                      className={`${
                        activateBtn ? "enabled" : "disabled"
                      } button-primary`}
                    >
                      Sign Up
                    </button>
                    <div className="auth__footer">
                      Already have an account?
                      <span
                        className="red__link__auth"
                        onClick={() => change()}
                      >
                        {" "}
                        Sign in to your account
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      ) : null}
    </div>
  );
};

export default SignupPopup;
