export const validateAddress = (address) => {
  let errorMessages = {};

  if (
    address.first_name &&
    !/^[a-zA-Z]+$/.test(address.first_name)
  ) {
    errorMessages.first_name =
      "First name must start with a letter and with no special character";
  }

  if (address.last_name && !/^[a-zA-Z]+$/.test(address.last_name)) {
    errorMessages.last_name =
      "Last name must start with a letter and with no special character";
  }

  if (
    (address.phone_number && address.phone_number.length < 10) ||
    (address.phone_number && address.phone_number.length > 10)
  ) {
    errorMessages.phone_number =
      "Remove the first '0' of your phone number: for example - 80...";
  }

 if (address.address && address.address.length > 45) {
    errorMessages.address = "Address maximum length is 45 characters";
  }

  if (address.city && !/^[a-zA-Z][a-zA-Z]/.test(address.city)) {
    errorMessages.city = "City can only be text";
  }

  return errorMessages;
};
