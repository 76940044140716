const Specification = ({ data }) => {
  if (data) {
    const {key_features} = data;
    return (
      <div>
        <div className="details">
          <div className="details_parts">
            <div className="key_features">
              {key_features?.split("\n").map((feat, i) => (
                <p key={i}>{feat}</p>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default Specification;
