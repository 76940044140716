import { currency_formatter, formatISODateToCustomFormat } from "../libries";

const TransactionPopup = ({ setOpenDeposit, clickedDeposit }) => {

  return (
    <div className="pop__page">
      <div className="transact__body">
        <h3 className="transact__heading">Transaction Details</h3>
        <div className="transact__info">
          <div className="transact__details">
            <div>
              <p className="transact__text">Transaction date</p>
              <p className="transact__text">Transaction ID</p>
              <p className="transact__text">Amount</p>
              <p className="transact__text">Status</p>
            </div>

            <div>
              <p className="transact__date">{formatISODateToCustomFormat(clickedDeposit.created_at)}</p>
              <p className="transact__date">{clickedDeposit.reference}</p>
              <p className="transact__date">{currency_formatter(clickedDeposit.amount)}</p>
              <p className="transact__date" style={{textTransform: "uppercase"}}>Completed</p>
            </div>
          </div>
        </div>

        <div className="transact__close">
          <button className="close__btn" onClick={() => setOpenDeposit(false)}>
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default TransactionPopup;
