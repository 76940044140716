import React from "react";

// components
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import CallCenter from "../components/CallCenter";

const Layout = ({ children, topPadding }) => (
  <>
    <Navbar />
    <main className={`main ${topPadding ? "pt-medium" : ""}`}>{children}</main>
    <CallCenter />
    <Footer />
  </>
);

export default Layout;
