import { useState, useEffect } from "react";
import { axiosInstance, baseUrl } from "../libries";
import { useSelector } from "react-redux";

import Empty from "../components/Empty";
import Pagination from "../components/Pagination";

const Reviews = () => {
  const token = useSelector((state) => state.auth.accessToken);

  // state to track loading state
  const [loading, setLoading] = useState(true);

  // state to store all reviews
  const [reviews, setReviews] = useState(null);

  // state to get review id to show its details
  const [reviewProdId, setReviewProdId] = useState(null);

  // state to hold number of items per page
  const [perPage, setPerPage] = useState(null);

  // state to get the last page
  const [lastPage, setLastPage] = useState(null);

  const handleGetReview = (id) => {
    setReviewProdId(reviews.find((review) => review.id === id));
  };

  // state for pagination links
  const [links, setLinks] = useState(null);

  // pagination implementation with the backend functionality
  const [url, setUrl] = useState(`${baseUrl}/all-reviews`);

  // state to catch network error
  const [networkError, setNetworkError] = useState(false);

  // showing alert states
  const [alertMsg, setAlertMsg] = useState("");

  // fetching product reviews

  useEffect(() => {
    setLoading(true);
    axiosInstance(token)
    .get(url)
      .then((resp) => {
        setLoading(false);
        setNetworkError(false);
        if (resp.data.data.products !== null) {
          const reviewsFetched = resp.data.data.products.data;
          const reviewsLinks = resp.data.data.products.meta.links;
          const reviewsPerPage = resp.data.data.products.meta.per_page;
          const reviewsLastPage = resp.data.data.products.meta.last_page;

          setReviews(reviewsFetched);
          setReviewProdId(reviewsFetched[0]);
          setPerPage(reviewsPerPage);
          setLinks(reviewsLinks);
          setLastPage(reviewsLastPage);
        }
      })
      .catch((err) => {
        setLoading(false);
        if (err.code === "ERR_BAD_REQUEST") {
          setAlertMsg(err.response.data.message);
        } else {
          setNetworkError(true);
          setAlertMsg(err.message);
        }
      });
  }, [token, url]);

  useEffect(() => {
    document.title = "ShopNig - Reviews";
  }, []);

  return (
    <div className="reviews">
      <div className="reviews__head">
        <p>Reviews</p>
        <div className="reviews__search">
        </div>
      </div>
      {loading && (
        <div className="loading__spin__address">
          <span className="loader"></span>
        </div>
      )}
      {!loading && !networkError && !reviewProdId && (
        <Empty
          emptyTitle="You have no reviews yet!"
          emptySubTitle=""
          buttonText="Rate a Product"
          link="/dashboard/orders"
        />
      )}

      {!loading && networkError && (
        <Empty
          emptyTitle={alertMsg}
          emptySubTitle="Something went wrong! TRY AGAIN.."
          network={true}
        />
      )}
      {!networkError && !loading && reviewProdId && (
        <div className="all__reviews">
          <div className="reviews__list">
            {reviews?.map((review) => (
              <div key={review.id}>
                <div
                  key={review.id}
                  className={`${
                    review.id === reviewProdId?.id && "active"
                  } review__item`}
                  onClick={() => handleGetReview(review.id)}
                >
                  <div className="review__item__prod">
                    <div className="prodImg__container">
                      <img
                        src={review.images[0].image_url}
                        alt={review.product.name}
                      />
                    </div>
                    <div className="review__prod">
                      <span className="review__prod__name">
                      {review.product.name.length > 30
                          ? review.product.name.slice(0, 30) + "..."
                          : review.product.name}
                      </span>
                      <span>Quantity: {review.quantity}</span>
                    </div>
                  </div>
                  <div className="review__delivery__time">
                    <span>
                      Reviewed On{" "}
                      {new Date(review.updated_at).toLocaleDateString("en-us", {
                        year: "numeric",
                        month: "numeric",
                        day: "numeric",
                      })}
                    </span>
                  </div>
                </div>

                {/* showing review details on mobile like accordion */}
                <div
                  className={`${
                    review.id === reviewProdId?.id
                      ? "show__review__mobile"
                      : "hide__review__mobile"
                  } mobile__review`}
                >
                  <span className="customer__review">
                    {reviewProdId?.caption}
                  </span>
                  <div>
                    {[...Array(5)].map((star, index) => {
                      const showRating = index + 1;
                      return (
                        <span
                          className={`${
                            parseInt(reviewProdId?.rating) >= showRating
                              ? "yellow"
                              : "grey"
                          } star`}
                          key={index}
                        >
                          <ion-icon name="star"></ion-icon>
                        </span>
                      );
                    })}
                  </div>
                  <p>{reviewProdId?.review_details}</p>
                  <div className="review__date">
                    <span>
                      {new Date(review.updated_at).toLocaleDateString("en-us", {
                        year: "numeric",
                        month: "numeric",
                        day: "numeric",
                      })}
                    </span>
                  </div>
                </div>
              </div>
            ))}
          </div>

          <div className="reviews__details">
            <span className="customer__review">{reviewProdId?.caption}</span>
            <div>
              {[...Array(5)].map((star, index) => {
                const showRating = index + 1;
                return (
                  <span
                    className={`${
                      parseInt(reviewProdId?.rating) >= showRating
                        ? "yellow"
                        : "grey"
                    } star`}
                    key={index}
                  >
                    <ion-icon name="star"></ion-icon>
                  </span>
                );
              })}
            </div>
            <p>{reviewProdId?.review_details}</p>
            <div className="review__date">
              <span>
                {new Date(reviewProdId.updated_at).toLocaleDateString("en-us", {
                  year: "numeric",
                  month: "numeric",
                  day: "numeric",
                })}
              </span>
            </div>
          </div>
        </div>
      )}
      {!loading &&
        (reviews?.length >= perPage || lastPage !== 1) &&
        !networkError && <Pagination links={links} setUrl={setUrl} />}
    </div>
  );
};

export default Reviews;
