import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  setBanners,
  setPhones,
  setBestDeal,
  setLatestProduct,
  setMostRatedProduct,
  setPopularCategories,
  setHighPrice,
  setLowPrice,
  setCategories,
  setListMenu,
} from "../store/slices/cacheSlice";

// components
import { axiosInstance } from "../libries";
import Layout from "../containers/Layout";
import { Banner } from "../components/Banners";
import Products from "../components/Products";
import ProductCategories from "../components/PopularCategories";
import BestBrand from "../components/BestBrand";
import Welcome from "../components/Welcome";

const Home = () => {
  const dispatch = useDispatch();

  const welcomePopup = useSelector((state) => state.auth.welcomePopup);

  // populating the banners, popular categories, latest product, best deal from global store
  const {
    popularCategories,
    latestProduct,
    mostRatedProduct,
    bestDeal,
    listMenu,
    phones,
  } = useSelector((state) => state.cache);

  // fetching categories for footer here
  useEffect(() => {
    axiosInstance()
      .get("/categories")
      .then((resp) => {
        const categoriesResp = resp.data.data.category;
        dispatch(setCategories(categoriesResp));
      })
      .catch((err) => {
        return err.response;
      });
  }, [dispatch]);

  useEffect(() => {
    axiosInstance()
      .get(`/products/phones`)
      .then((resp) => {
        const phonesResp = resp.data.data.products;
        dispatch(setPhones(phonesResp));
      })
      .catch((err) => {});
  }, [dispatch]);

  useEffect(() => {
    axiosInstance()
      .get(`/products/best-deals`)
      .then((resp) => {
        const bestDealResp = resp.data.data.products;
        dispatch(setBestDeal(bestDealResp));
      })
      .catch((err) => {});
  }, [dispatch]);

  useEffect(() => {
    axiosInstance()
      .get(`/products/latest-products`)
      .then((resp) => {
        const latestProductResp = resp.data.data.products;
        dispatch(setLatestProduct(latestProductResp));
      })
      .catch((err) => {});
  }, [dispatch]);

  useEffect(() => {
    axiosInstance()
      .get(`/products-most-rated`)
      .then((resp) => {
        const mostRatedProductResp = resp.data.data.products;
        dispatch(setMostRatedProduct(mostRatedProductResp));
      })
      .catch((err) => {});
  }, [dispatch]);

  useEffect(() => {
    axiosInstance()
      .get(`/categories/popular`)
      .then((resp) => {
        const popularCategories = resp.data.data.popular_categories;
        dispatch(setPopularCategories(popularCategories));
      })
      .catch((err) => {});
  }, [dispatch]);

  useEffect(() => {
    axiosInstance()
      .get(`/banner/home-top`)
      .then((resp) => {
        const bannersResp = resp.data.data.banners;
        dispatch(setBanners(bannersResp));
      })
      .catch((err) => {});
  }, [dispatch]);

  // getting price range here
  useEffect(() => {
    axiosInstance()
      .get(`/products/filter/amount/ranges`)
      .then((resp) => {
        const price = resp.data.data;
        dispatch(setLowPrice(price.min_amount));
        dispatch(setHighPrice(price.max_amount));
      })
      .catch((err) => {});
  }, [dispatch]);

  // getting price range here
  useEffect(() => {
    axiosInstance()
      .get(`/list-menu`)
      .then((resp) => {
        const listMenuResp = resp.data.data.menus;
        dispatch(setListMenu(listMenuResp));
      })
      .catch((_err) => {});
  }, [dispatch]);

  useEffect(() => {
    document.title = "ShopNig - The hassle free shopping experience";
  }, []);

  return (
    <>
      <Layout topPadding>
        <Banner />
        <section className="container home-categories">
          {listMenu?.map((menu, i) => (
            <div className="home-categories__category" key={i}>
              <ion-icon name={menu.icon}></ion-icon>
              {menu.name}
            </div>
          ))}
        </section>
        <ProductCategories data={popularCategories} />
        <BestBrand />
        <Products title="Today's Best Deal" data={bestDeal} isNew={false} />
        <Products
          title="Latest Products on ShopNig"
          data={latestProduct}
          isNew={true}
        />
        <Products title="Smart Phones" data={phones} isNew={false} />
        <Products
          title="Most Rated Products"
          data={mostRatedProduct}
          isNew={false}
        />
      </Layout>
      {!welcomePopup && <Welcome />}
    </>
  );
};

export default Home;
