import { useState, useEffect } from "react";
import { axiosInstance } from "../libries";

//components
import Layout from "../containers/Layout";
import Breadcrumb from "../components/Breadcrumb";
import HelpCenter from "../components/HelpCenter";

const TermsCondition = () => {
  // state to set active tabs
  const [activeHelpTabs, setActiveHelpTabs] = useState("terms");

  const [terms, setTerms] = useState(null);

  // state to track loading state
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axiosInstance()
      .get(`/help-center/terms-and-condition`)
      .then((resp) => {
        setTerms(resp.data.data.message);
      })
      .catch((err) => {})
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    document.title = "ShopNig - Terms and Conditions";
  }, []);

  return (
    <Layout>
      <Breadcrumb name="Product detail >" />
      <div className="container">
        <div className="help">
          <HelpCenter
            activeHelpTabs={activeHelpTabs}
            setActiveHelpTabs={setActiveHelpTabs}
          />
          {loading && (
            <div className="loading__spin__address">
              <span className="loader"></span>
            </div>
          )}
          {!loading &&
            terms?.map((term, i) => (
              <div className="terms" key={i}>
                <h5 className="terms__title">{term.title}</h5>
                <div className="terms__body">
                  {term.description.split("\n\n").map((item, i) => (
                    <p className="condition" key={i}>
                      {item}
                    </p>
                  ))}
                </div>
              </div>
            ))}
        </div>
      </div>
    </Layout>
  );
};

export default TermsCondition;
