export const STYLES = {
  "--body-bg": { light: "#efefef", dark: "#0F241A" },
  "--footer-bg": { light: "#232323", dark: "#07120D" },
  "--login-bg": { light: "#f4f9ec", dark: "#163627" },
  "--login-border": { light: "#ebebeb", dark: "#1E4834" },
  "--login-input": { light: "#000", dark: "#ffffff" },
  "--text-color": { light: "#2B2B2B", dark: "#D9D9D9" },
  "--text-slashed": { light: "#909090", dark: "#909090" },
  "--header-bg": { light: "#FFF", dark: "#0F241A" },
  "--header-border": { light: "#eee", dark: "#1E4834" },
  "--drop-hover": { light: "#7bb20e", dark: "#1E4834" },
  "--menu-hover": { light: "#f1f1f1", dark: "#1E4834" },
  "--navtab-bg": { light: "#FFF", dark: "#163627" },
  "--loader": { light: "#a9a9a9", dark: "#163627" },
  "--loader-light": { light: "#ddd", dark: "#2d4a3d" },
  "--loader-dark": { light: "#d0d0d0", dark: "#143123" },
  "--search-bg": { light: "#d8d8d8", dark: "#0A1912" },
  "--search-header": { light: "none", dark: "#0A1912" },
  "--search-border": { light: "#d8d8d8", dark: "#1E4834" },
  "--add-btn": { light: "none", dark: "#0A1912" },
  "--btn-bg": { light: "none", dark: "#D9D9D9" },
  "--sidebar-border": { light: "#eaeaea", dark: "#1E4834" },
  "--prod-title": { light: "#545454", dark: "#D9D9D9" },
  "--prod-text": { light: "#545454", dark: "#cccccc" },
  "--prod-border": { light: "#d8d8d8", dark: "1E4834" },
  "--chev-btn": { light: "#141414", dark: "#d9d9d9" },
  "--select-bg": { light: "none", dark: "1E4834" },
  "--cart-btn": { light: "#ffffff", dark: "none" },
  "--cart-border": { light: "#e6e6e6", dark: "#1E4834" },
  "--similar-border": { light: "#D8D8D8", dark: "#444444" },
  "--checkout-border": { light: "#E6E6E6", dark: "#1E4834" },
  "--check-border": { light: "#d0d0d0", dark: "#1E4834" },
  "--choose-bg": { light: "#efefef", dark: "#163627" },
  "--account-text": { light: "#555555", dark: "#A6A6A6" },
  "--input-num": { light: "#f1f1f1", dark: "#314607" },
  "--input-bg": { light: "#ffffff", dark: "#0A1912" },
  "--mail-bg": { light: "#ededed", dark: "#4d4d4d" },
  "--rank-fig": { light: "#B0B0B0", dark: "#737373" },
  "--rank-info": { light: "#b0b0b0", dark: "#d9d9d9" },
  "--order-text": { light: "#808080", dark: "#A6A6A6" },
  "--deliver-bg": { light: "#edf2fa", dark: "#182E4E" },
  "--deliver-border": { light: "#D9D9D9", dark: "#1E4834" },
  "--deliver-border2": { light: "#cccccc", dark: "#1E4834" },
  "--pay-bg": { light: "#ffffff", dark: "#1B412F" },
  "--note-text": { light: "#808080", dark: "#999999" },
  "--insufficient-bg": { light: "#f9faf8", dark: "#163627" },
  "--insufficient-text": { light: "#727272", dark: "#999999" },
  "--insufficient-num": { light: "#fafafa", dark: "#0A1912" },
  "--thanks-details": { light: "#fcfcee", dark: "#57570F" },
  "--rate-product": { light: "#ffffff", dark: "#none" },
  "--rate-text": { light: "#000", dark: "#999999" },
  "--form-color": { light: "#000", dark: "#d9d9d9" },
  "--form-placeholder": { light: "#bebecb", dark: "#595959" },
  "--wallet-bg": { light: "#fafafa", dark: "#1B412F" },
  "--order-bg": { light: "#efefef", dark: "#0F241A" },
  "--order-border": { light: "#dddddd", dark: "#1E4834" },
  "--pending-bg": { light: "#fbf4d0", dark: "#403C26" },
  "--cancelled-bg": { light: "#Fdf2f1", dark: "#402726" },
  "--processing-bg": { light: "#fcdecf", dark: "#402F26" },
  "--shipped-bg": { light: "#CCE0FF", dark: "#263040" },
  "--details-grey": { light: "#eaeaea", dark: "#163627" },
  "--details-green": { light: "#E1FFCC", dark: "#314026" },
  "--green-text": { light: "#397305", dark: "#61C309" },
  "--details-blue": { light: "#cce0ff", dark: "#263030" },
  "--details-text": { light: "#000", dark: "#d9d9d9" },
  "--details-info": { light: "#000", dark: "#163627" },
  "--details-total": { light: "#edf2fa", dark: "#214378" },
  "--delivery-address": { light: "#edf2fa", dark: "#162c50" },
  "--delivery-border": { light: "#bebecb", dark: "#1E4834" },
  "--change-default": { light: "#edf2fa", dark: "#2C6ED1" },
  "--phone-bg": { light: "#f1fcdc", dark: "#314607" },
  "--input-border": { light: "#e6e6e6", dark: "#1E4834" },
  "--help-text": { light: "#444444", dark: "#d9d9d9" },
  "--help-hover": { light: "#444444", dark: "#444444" },
  "--about-text": { light: "#545454", dark: "#A6A6A6" },
  "--about-list": { light: "#909090", dark: "#A6A6A6" },
  "--about-shadow": { light: "#cccccc4d", dark: "#141414" },
  "--contact-border": { light: "#dddcdc", dark: "#1E4834" },
  "--terms-text": { light: "#545454", dark: "#B3B3B3" },
  "--notification-bg": { light: "#f7faf5", dark: "#1B412F" },
  "--notification-bg2": { light: "none", dark: "#214F39" },
  "--invalid-bg": { light: "#ffffff", dark: "#241500" },
  "--reset-text": { light: "#808080", dark: "#D9D9D9" },
  "--input-color": { light: "#000", dark: "#fff" },
  "--verify-close": { light: "#efefef", dark: "#0A1912" },
  "--transact-text": { light: "#737373", dark: "#bebebe" },
  "--kyc-reviewingbg": {light: "#FFF6E4", dark: "#332200"},
  "--kyc-subheading": {light: "#7D7D7D", dark: "#999999"},
  "--kyc-input-border": {light: "#e9e9e9", dark: "#163627"},
};

export const set_mode = (mode) => {
  for (const [variable, style] of Object.entries(STYLES)) {
    document.documentElement.style.setProperty(variable, style[mode]);
  }

  localStorage.setItem("uiMode", mode);
};
