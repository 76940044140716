import { useState } from "react";
import LoginPopup from "./LoginPopup";
import SignupPopup from "./SignupPopup";

const SignLogin = ({ closePopup, setClosePopup, myCurrentLocation }) => {
  const [current, setCurrent] = useState("login");

  if (current === "login")
    return (
      <LoginPopup
        change={() => setCurrent("signup")}
        setClosePopup={setClosePopup}
        closePopup={closePopup}
        myCurrentLocation={myCurrentLocation}
      />
    );
  else
    return (
      <SignupPopup
        change={() => setCurrent("login")}
        setClosePopup={setClosePopup}
        closePopup={closePopup}
        myCurrentLocation={myCurrentLocation}
      />
    );
};

export default SignLogin;
