import React from "react";

const AccountCard = ({ title, details, icon }) => {
  let borders = null;

  return (
    <div className={`myAccount__card ${borders}`}>
      <div className="myAccount__cardAside">
        <div className="myAccount__cardIcon">
          <img src={icon} alt="" srcSet="" />
        </div>
      </div>
      <div className="myAccount__cardMain">
        <p className="details">{details}</p>
      </div>
    </div>
  );
};

export default AccountCard;
