import { createSlice, current } from "@reduxjs/toolkit";
import { getState, saveState } from "../global";

const initialState = {
  banners: null,
  popularCategories: null,
  phones: null,
  bestBrands: null,
  latestProduct: null,
  mostRatedProduct: null,
  bestDeal: null,
  listMenu: null,

  minProdPrice: null,
  maxProdPrice: null,

  // call center  caching
  orders: null,
  logistics: null,
  disputes: null,

  // category caching
  categories: null,
  brands: null,

  // social-handles
  socials: null,
};

const state = getState("cache", initialState);

const cacheSlice = createSlice({
  name: "cache",
  initialState: state,
  reducers: {
    setBanners(state, { payload }) {
      state.banners = payload;

      saveState("cache", current(state));
    },
    setPopularCategories(state, { payload }) {
      state.popularCategories = payload;

      saveState("cache", current(state));
    },
    setBestBrands(state, { payload }) {
      state.bestBrands = payload;

      saveState("cache", current(state));
    },
    setPhones(state, { payload }) {
      state.phones = payload;

      saveState("cache", current(state));
    },
    setLatestProduct(state, { payload }) {
      state.latestProduct = payload;

      saveState("cache", current(state));
    },
    setMostRatedProduct(state, { payload }) {
      state.mostRatedProduct = payload;

      saveState("cache", current(state));
    },
    setBestDeal(state, { payload }) {
      state.bestDeal = payload;

      saveState("cache", current(state));
    },
    setListMenu(state, { payload }) {
      state.listMenu = payload;

      saveState("cache", current(state));
    },
    setOrdersRep(state, { payload }) {
      state.orders = payload;

      saveState("cache", current(state));
    },
    setLogisticsRep(state, { payload }) {
      state.logistics = payload;

      saveState("cache", current(state));
    },
    setDisputesRep(state, { payload }) {
      state.disputes = payload;

      saveState("cache", current(state));
    },
    setCategories(state, { payload }) {
      state.categories = payload;

      saveState("cache", current(state));
    },
    setBrands(state, { payload }) {
      state.brands = payload;

      saveState("cache", current(state));
    },
    setLowPrice(state, { payload }) {
      state.minProdPrice = payload;

      saveState("cache", current(state));
    },
    setHighPrice(state, { payload }) {
      state.maxProdPrice = payload;

      saveState("cache", current(state));
    },
    updateSocials(state, { payload }) {
      state.socials = payload;

      saveState("cache", current(state));
    },
  },
});

export const {
  setBanners,
  setBestBrands,
  setPhones,
  setBestDeal,
  setListMenu,
  setLatestProduct,
  setMostRatedProduct,
  setPopularCategories,
  setLogisticsRep,
  setDisputesRep,
  setOrdersRep,
  setCategories,
  setBrands,
  setLowPrice,
  setHighPrice,
  updateSocials
} = cacheSlice.actions;

export default cacheSlice.reducer;
