const OrderCard = ({ info, index }) => {
  return (
    <div key={index} className={`${info.color} orderCard`}>
      <div className="orderCard__name">
        <img src={info.iconOfOrder} alt={info.orderTrack} />
        <span>{info.orderTrack}</span>
      </div>
      <p className="orderCard__val">
        {info.orderValue}{" "}
        {info.orderStatus === "refunded".toUpperCase() || info.orderStatus === "cancelled".toUpperCase()
          ?  (`(${info.orderStatus})`)
          : null}
        {info.deliveryMtd ? (
          <span className="delivery__method">{info.deliveryMtd}</span>
        ) : null}
      </p>
    </div>
  );
};

export default OrderCard;
