import React from "react";

const Details = ({ data }) => {
  return (
    <div
      className="product_details_text"
      dangerouslySetInnerHTML={{ __html: data }}
    />
  );
};

export default Details;
