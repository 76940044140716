import { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { axiosInstance } from "../libries";

import { updateVerifyBtnClick, updateVerifyUserInfo } from "../store/slices/authSlice";

function VerifyModal() {

    const dispatch = useDispatch();
    const navigate = useNavigate();

const {verifyBtnClick, verifyUserInfo}  = useSelector((state) => state.auth);

const token = useSelector((state) => state.auth.accessToken);

  const [openReviewingModal, setOpenReviewingModal] = useState(false);
  const [submitBvnModal, setSubmitBvnModal] = useState(false);
  const [selfieModal, setSelfieModal] = useState(false);

  const [loading, setLoading] = useState(false)

  const [picture, setPicture] = useState("")

  const [bvn, setBvn] = useState("");

  const [stream, setStream] = useState(null)
  const [selfieError, setSelfieError] = useState("")

  const [verifyError, setVerifyError] = useState("")
  const [showVerifyError, setshowVerifyError] = useState("")

  const videoEl = useRef()
  const pictureEl = useRef()

  const handleTakePicture = () => {
    pictureEl.current.getContext("2d")?.drawImage(videoEl.current, 0, 0, pictureEl.current.width, pictureEl.current.height);

    setPicture(pictureEl.current.toDataURL("image/jpeg"))
  }

  const handleProceedSelfie = () => {
    if(!picture) return setSelfieError("You need to take your picture")

      dispatch(updateVerifyUserInfo({
        bvn,
        picture    
      }))

      dispatch(updateVerifyBtnClick(true))
      setSelfieModal(false)
      setOpenReviewingModal(true)
  }

const handleVerifyUser = () => {
  if(!verifyUserInfo) return;

  setLoading(true)

  axiosInstance(token)
  .post(`/accounts/kyc/liveliness-check`, {
    media_file: verifyUserInfo.picture.split(",")[1],
    bvn: verifyUserInfo.bvn
  })
  .then((_res) => {
    dispatch(updateVerifyBtnClick(false))

    navigate("/dashboard")
    
  })
  .catch((err) => {
    setshowVerifyError(true)
    if (err.code === "ERR_BAD_REQUEST") {
      setVerifyError(err.response.data.message);
    } else {
      setVerifyError(err.message);
    }
  })
  .finally(() => {setLoading(false);
    dispatch(updateVerifyUserInfo(null))
    setBvn("")
    setPicture("")
  })
}

  useEffect(() => {
    if(selfieModal) {
    async function startCamera() {
      try {
        const mediaStream = await navigator.mediaDevices.getUserMedia({
          video: true,
          audio: false
        })
        videoEl.current.srcObject = mediaStream;

        setStream(mediaStream)
      } catch (error) {
        setSelfieError("Error accessing camera.")
      }
    }

    startCamera();
}
  }, [selfieModal, selfieError])
  

  useEffect(() => {
    if(!stream) return;
        
    return () => stream.getTracks().forEach((track) => track.stop())
  }, [stream])


    return(
        <>
        {verifyBtnClick && !submitBvnModal && !selfieModal && !openReviewingModal &&
      <div className="kyc-modal">
      <div className="kyc-modal__container">
        <div className="kyc-modal__close">
          <span onClick={() => dispatch(updateVerifyBtnClick(false))}>
      <svg width="32" height="32" viewBox="0 0 32 32" fill="none" className="kyc-modal__close-icon" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.01172 22.9906L16.0024 15.9999L22.9931 22.9906M22.9931 9.00928L16.0011 15.9999L9.01172 9.00928" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg></span>
    </div>
      <div className="kyc-modal__main">
      <div className="kyc-modal__heading">
        <h3 className="">Verify your account</h3>
        <p>We need some information to confirm your identity.</p>
      </div>
        <div className="kyc-modal__form">
          <div className="kyc-modal__step">
            <p>Submit your BVN</p>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 17C12.2833 17 12.521 16.904 12.713 16.712C12.905 16.52 13.0007 16.2827 13 16C12.9993 15.7173 12.9033 15.48 12.712 15.288C12.5207 15.096 12.2833 15 12 15C11.7167 15 11.4793 15.096 11.288 15.288C11.0967 15.48 11.0007 15.7173 11 16C10.9993 16.2827 11.0953 16.5203 11.288 16.713C11.4807 16.9057 11.718 17.0013 12 17ZM12 13C12.2833 13 12.521 12.904 12.713 12.712C12.905 12.52 13.0007 12.2827 13 12V8C13 7.71667 12.904 7.47933 12.712 7.288C12.52 7.09667 12.2827 7.00067 12 7C11.7173 6.99933 11.48 7.09533 11.288 7.288C11.096 7.48067 11 7.718 11 8V12C11 12.2833 11.096 12.521 11.288 12.713C11.48 12.905 11.7173 13.0007 12 13ZM12 22C10.6167 22 9.31667 21.7373 8.1 21.212C6.88334 20.6867 5.825 19.9743 4.925 19.075C4.025 18.1757 3.31267 17.1173 2.788 15.9C2.26333 14.6827 2.00067 13.3827 2 12C1.99933 10.6173 2.262 9.31733 2.788 8.1C3.314 6.88267 4.02633 5.82433 4.925 4.925C5.82367 4.02567 6.882 3.31333 8.1 2.788C9.318 2.26267 10.618 2 12 2C13.382 2 14.682 2.26267 15.9 2.788C17.118 3.31333 18.1763 4.02567 19.075 4.925C19.9737 5.82433 20.6863 6.88267 21.213 8.1C21.7397 9.31733 22.002 10.6173 22 12C21.998 13.3827 21.7353 14.6827 21.212 15.9C20.6887 17.1173 19.9763 18.1757 19.075 19.075C18.1737 19.9743 17.1153 20.687 15.9 21.213C14.6847 21.739 13.3847 22.0013 12 22Z" fill="#E57D43"/>
            </svg>

          </div>
          <div className="kyc-modal__step">
            <p>Selfie upload</p>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 17C12.2833 17 12.521 16.904 12.713 16.712C12.905 16.52 13.0007 16.2827 13 16C12.9993 15.7173 12.9033 15.48 12.712 15.288C12.5207 15.096 12.2833 15 12 15C11.7167 15 11.4793 15.096 11.288 15.288C11.0967 15.48 11.0007 15.7173 11 16C10.9993 16.2827 11.0953 16.5203 11.288 16.713C11.4807 16.9057 11.718 17.0013 12 17ZM12 13C12.2833 13 12.521 12.904 12.713 12.712C12.905 12.52 13.0007 12.2827 13 12V8C13 7.71667 12.904 7.47933 12.712 7.288C12.52 7.09667 12.2827 7.00067 12 7C11.7173 6.99933 11.48 7.09533 11.288 7.288C11.096 7.48067 11 7.718 11 8V12C11 12.2833 11.096 12.521 11.288 12.713C11.48 12.905 11.7173 13.0007 12 13ZM12 22C10.6167 22 9.31667 21.7373 8.1 21.212C6.88334 20.6867 5.825 19.9743 4.925 19.075C4.025 18.1757 3.31267 17.1173 2.788 15.9C2.26333 14.6827 2.00067 13.3827 2 12C1.99933 10.6173 2.262 9.31733 2.788 8.1C3.314 6.88267 4.02633 5.82433 4.925 4.925C5.82367 4.02567 6.882 3.31333 8.1 2.788C9.318 2.26267 10.618 2 12 2C13.382 2 14.682 2.26267 15.9 2.788C17.118 3.31333 18.1763 4.02567 19.075 4.925C19.9737 5.82433 20.6863 6.88267 21.213 8.1C21.7397 9.31733 22.002 10.6173 22 12C21.998 13.3827 21.7353 14.6827 21.212 15.9C20.6887 17.1173 19.9763 18.1757 19.075 19.075C18.1737 19.9743 17.1153 20.687 15.9 21.213C14.6847 21.739 13.3847 22.0013 12 22Z" fill="#E57D43"/>
            </svg>

          </div>
        </div>
        <div className="kyc-modal__footer">
          <button className="button button--active" onClick={() => {
            dispatch(updateVerifyBtnClick(true))
            setSubmitBvnModal(true)
          }}>Start Verification</button>
        </div>
      </div>
    </div>
    </div>
      }

    {submitBvnModal && verifyBtnClick && <div className="kyc-modal">
          <div className="kyc-modal__container">
            <div className="kyc-modal__close">
              <span onClick={() => dispatch(updateVerifyBtnClick(false))}>
              <svg width="32" height="32" viewBox="0 0 32 32" fill="none" className="kyc-modal__close-icon" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.01172 22.9906L16.0024 15.9999L22.9931 22.9906M22.9931 9.00928L16.0011 15.9999L9.01172 9.00928" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg></span>
        </div>
          <div className="kyc-modal__main">
          <div className="kyc-modal__heading">
            <h3 className="">Submit your BVN</h3>
            <p>Let's make sure you’re you</p>
          </div>
            <div className="kyc-modal__bvn-form">
              <p>Kindly enter your BVN</p>
              <input type="number" placeholder="Enter bvn" className="kyc-modal__bvn-input" onChange={(e) => setBvn(e.target.value)} max={11} min={11} />
            </div>
            <div className="kyc-modal__notes">
            You can check your BVN by simply dialing the code *565*0# using the phone number you used to register your BVN. By following the prompts displayed after you dial the code, your BVN will be displayed on your mobile device for a service fee of N20.
            </div>
            <div className="kyc-modal__footer">
              <button className={`${bvn.length === 11 ? "button--active" : ""} button`} onClick={() => {
                if(bvn.length !== 11) return;
            dispatch(updateVerifyBtnClick(true))
            dispatch(updateVerifyUserInfo({
              bvn
            }))
            setSubmitBvnModal(false)
            setSelfieModal(true)
          }}>Proceed</button>
            </div>
          </div>
        </div>
    </div>}

    {selfieModal  && verifyBtnClick && 
      <div className="kyc-modal">
      <div className="kyc-modal__container">
        <div className="kyc-modal__close">
        <span onClick={() => {
          dispatch(updateVerifyBtnClick(false))
          setBvn("")
          }}>
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" className="kyc-modal__close-icon" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.01172 22.9906L16.0024 15.9999L22.9931 22.9906M22.9931 9.00928L16.0011 15.9999L9.01172 9.00928" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg></span>
    </div>
      <div className="kyc-modal__main">
      <div className="kyc-modal__heading">
        <h3 className="">Selfie upload</h3>
        <p>Let's make sure you’re you</p>
      </div>
        <div className="kyc-modal__img">
          <video ref={videoEl} autoPlay className={`${picture && "hidden"} kyc-modal__img-capture`}>
          </video>
          <canvas className="kyc-modal__img-capture hidden" ref={pictureEl}></canvas>
          <div className={`${picture && "hidden" } kyc-modal__footer`}>
          <button className="button button--active" onClick={handleTakePicture}>Capture</button>
        </div>
          {picture ? 
          <>
          <img src={picture} alt="selfie"  className="kyc-modal__img-capture"/> 
          <div className="kyc-modal__footer">
          <button className="button button--active" onClick={handleProceedSelfie}>Submit</button>
          <p onClick={() => setPicture("")}>Retake</p>
        </div>
          </>: null}
        </div>
        
      </div>
    </div>
    </div>
      }

    {openReviewingModal  && verifyBtnClick && 
      <div className="kyc-modal">
      <div className="kyc-modal__container">
        <div className="kyc-modal__close">
        <span onClick={() => {dispatch(updateVerifyBtnClick(false))
          setOpenReviewingModal(false)
          setVerifyError("")
          setBvn("")
          setshowVerifyError(false)
        }}>
      <svg width="32" height="32" viewBox="0 0 32 32" fill="none" className="kyc-modal__close-icon" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.01172 22.9906L16.0024 15.9999L22.9931 22.9906M22.9931 9.00928L16.0011 15.9999L9.01172 9.00928" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg></span>
    </div>
      <div className="kyc-modal__main">
      <div className="kyc-modal__heading">
        <h3 className="">Verify your account</h3>
        <p>We need some information to confirm your identity.</p>
      </div>
        <div className="kyc-modal__form">
          <div className="kyc-modal__step">
            <p>Submit your BVN</p>
            <div className="kyc-modal__reviewing">
              Reviewing
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.0013 14.1665C10.2374 14.1665 10.4355 14.0865 10.5955 13.9265C10.7555 13.7665 10.8352 13.5687 10.8346 13.3332C10.8341 13.0976 10.7541 12.8998 10.5946 12.7398C10.4352 12.5798 10.2374 12.4998 10.0013 12.4998C9.76519 12.4998 9.56741 12.5798 9.40797 12.7398C9.24853 12.8998 9.16852 13.0976 9.16797 13.3332C9.16741 13.5687 9.24741 13.7668 9.40797 13.9273C9.56852 14.0879 9.7663 14.1676 10.0013 14.1665ZM10.0013 10.8332C10.2374 10.8332 10.4355 10.7532 10.5955 10.5932C10.7555 10.4332 10.8352 10.2354 10.8346 9.99984V6.6665C10.8346 6.43039 10.7546 6.23261 10.5946 6.07317C10.4346 5.91373 10.2369 5.83373 10.0013 5.83317C9.76575 5.83261 9.56797 5.91261 9.40797 6.07317C9.24797 6.23373 9.16797 6.4315 9.16797 6.6665V9.99984C9.16797 10.2359 9.24797 10.434 9.40797 10.594C9.56797 10.754 9.76575 10.8337 10.0013 10.8332ZM10.0013 18.3332C8.84853 18.3332 7.76519 18.1143 6.7513 17.6765C5.73741 17.2387 4.85547 16.6451 4.10547 15.8957C3.35547 15.1462 2.76186 14.2643 2.32464 13.2498C1.88741 12.2354 1.66853 11.1521 1.66797 9.99984C1.66741 8.84761 1.8863 7.76428 2.32464 6.74984C2.76297 5.73539 3.35658 4.85345 4.10547 4.104C4.85436 3.35456 5.7363 2.76095 6.7513 2.32317C7.7663 1.88539 8.84964 1.6665 10.0013 1.6665C11.153 1.6665 12.2363 1.88539 13.2513 2.32317C14.2663 2.76095 15.1482 3.35456 15.8971 4.104C16.646 4.85345 17.2399 5.73539 17.6788 6.74984C18.1177 7.76428 18.3363 8.84761 18.3346 9.99984C18.333 11.1521 18.1141 12.2354 17.678 13.2498C17.2419 14.2643 16.6482 15.1462 15.8971 15.8957C15.146 16.6451 14.2641 17.239 13.2513 17.6773C12.2385 18.1157 11.1552 18.3343 10.0013 18.3332Z" fill="#FFA800"/>
            </svg>
            </div>
          </div>
          <div className="kyc-modal__step">
            <p>Selfie upload</p>
            <div className="kyc-modal__reviewing">
              Reviewing
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.0013 14.1665C10.2374 14.1665 10.4355 14.0865 10.5955 13.9265C10.7555 13.7665 10.8352 13.5687 10.8346 13.3332C10.8341 13.0976 10.7541 12.8998 10.5946 12.7398C10.4352 12.5798 10.2374 12.4998 10.0013 12.4998C9.76519 12.4998 9.56741 12.5798 9.40797 12.7398C9.24853 12.8998 9.16852 13.0976 9.16797 13.3332C9.16741 13.5687 9.24741 13.7668 9.40797 13.9273C9.56852 14.0879 9.7663 14.1676 10.0013 14.1665ZM10.0013 10.8332C10.2374 10.8332 10.4355 10.7532 10.5955 10.5932C10.7555 10.4332 10.8352 10.2354 10.8346 9.99984V6.6665C10.8346 6.43039 10.7546 6.23261 10.5946 6.07317C10.4346 5.91373 10.2369 5.83373 10.0013 5.83317C9.76575 5.83261 9.56797 5.91261 9.40797 6.07317C9.24797 6.23373 9.16797 6.4315 9.16797 6.6665V9.99984C9.16797 10.2359 9.24797 10.434 9.40797 10.594C9.56797 10.754 9.76575 10.8337 10.0013 10.8332ZM10.0013 18.3332C8.84853 18.3332 7.76519 18.1143 6.7513 17.6765C5.73741 17.2387 4.85547 16.6451 4.10547 15.8957C3.35547 15.1462 2.76186 14.2643 2.32464 13.2498C1.88741 12.2354 1.66853 11.1521 1.66797 9.99984C1.66741 8.84761 1.8863 7.76428 2.32464 6.74984C2.76297 5.73539 3.35658 4.85345 4.10547 4.104C4.85436 3.35456 5.7363 2.76095 6.7513 2.32317C7.7663 1.88539 8.84964 1.6665 10.0013 1.6665C11.153 1.6665 12.2363 1.88539 13.2513 2.32317C14.2663 2.76095 15.1482 3.35456 15.8971 4.104C16.646 4.85345 17.2399 5.73539 17.6788 6.74984C18.1177 7.76428 18.3363 8.84761 18.3346 9.99984C18.333 11.1521 18.1141 12.2354 17.678 13.2498C17.2419 14.2643 16.6482 15.1462 15.8971 15.8957C15.146 16.6451 14.2641 17.239 13.2513 17.6773C12.2385 18.1157 11.1552 18.3343 10.0013 18.3332Z" fill="#FFA800"/>
            </svg>
            </div>
          </div>
        </div>
        <div className="kyc-modal__footer">
          {showVerifyError && <span className="kyc-modal__error-display">{verifyError}</span>}
          {!showVerifyError ? <button className="button button--active" onClick={handleVerifyUser}>{loading ? "Verifying.." : "Verify Information"}</button>
          : <button className="button button--active" onClick={() => {
            setOpenReviewingModal(false)
            setVerifyError("")
            setshowVerifyError(false)
          }}>Retry</button>}
        </div>
      </div>

      
    </div>
    </div>
      }


        </>
    )
}



export default function VerifyModalWrapper() {
    const userDetails = useSelector(
      (state) => state.auth.userDetails
    );
  
    if (!userDetails || userDetails.verification.is_verified === 1) return null;
  
    return <VerifyModal />;
  }