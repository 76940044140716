import { createSlice, current } from "@reduxjs/toolkit";

import { getState, saveState } from "../global";

import { set_mode } from "../../utils/app";

const AppInitialState = {
  uiMode: localStorage.getItem("uiMode") || "light",
  codeSend: {},
};

const appSlice = createSlice({
  name: "app",
  initialState: getState("app", AppInitialState),
  reducers: {
    changeMode(state, { payload }) {
      state.uiMode = payload;

      set_mode(state.uiMode);

      saveState("app", current(state));
    },
    updateCodeSend(state, { payload }) {
      state.codeSend[payload.key] = payload.value;

      saveState("app", current(state));
    },
  },
});

export const { changeMode, updateCodeSend } = appSlice.actions;

export default appSlice.reducer;
