import React from "react";
import VerticalBarLoader from "../../pages/VerticalBarLoader";

const ProdPreviewLoader = () => {
  return (
    <div className="prod-loader">
      <VerticalBarLoader />
    </div>
  );
};

export default ProdPreviewLoader;
