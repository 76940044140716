import sms from "../assets/new-imgs/sms.png";
import whatsapp from "../assets/new-imgs/whatsapp.png";

import { useSelector, useDispatch } from "react-redux";
import { axiosInstance } from "../libries";
import useAlert from "../hooks/useAlert";
import { useEffect, useState, useRef } from "react";
import { updateCodeSend } from "../store/slices/appSlice";

const EnterNumber = ({
  handleCloseNumber,
  setCartStatus,
  setCartMessage,
  setShowEnterNumber,
  setShowCartNotify,
}) => {
  const token = useSelector((state) => state.auth.accessToken);
  const codeSend = useSelector((state) => state.app.codeSend);

  const submitPhoneRef = useRef();
  const dispatch = useDispatch();

  const [phone_number, setPhone_number] = useState("");

  const [smsType, setSmsType] = useState("whatsapp");

  const [code, setCode] = useState("");

  const modeType = "phone_number";

  const [message, setMessage, clearMessage] = useAlert();

  const activateBtn = phone_number && code;

  const [resendBlocked, setResendBlocked] = useState(0);
  const [resendBlockedInterval, setResendBlockedInterval] = useState(null);
  const [resendBlockedFor, setResendBlockedFor] = useState("");

  const runValidations = () => {
    if (phone_number.includes("+234")) {
      setMessage("warning", "phone number should not start with +234");
      return false;
    }

    if (phone_number.length < 10) {
      setMessage("warning", "phone number must contain 10 digits");
      return false;
    }

    if (phone_number[0] === "0") {
      setMessage("warning", "phone number should not start with 0");
      return false;
    }

    return true;
  };

  const requestCode = (e) => {
    const target = e.target;

    if (!runValidations()) return;

    target.innerHTML = `<span class="fas fa-spinner fa-spin"></span>`;
    target.setAttribute("disabled", "disabled");

    axiosInstance(token)
      .post(`/accounts/phone-number/code`, {
        mode: modeType,
        phone_number: `+234${phone_number}`,
        option: smsType,
      })
      .then((res) => {
        if (!res.data.status) {
          setMessage("warning", res.data.message);
        } else {
          setMessage("success", res.data.data.message);

          dispatch(
            updateCodeSend({
              key: phone_number,
              value: (Date.now() + 120000).toString(),
            })
          );
        }
      })
      .catch((err) => {
        if (err.code === "ERR_BAD_REQUEST") {
          setMessage("warning", err.response.data.message);
        } else {
          setMessage("error", err.message);
        }
      })
      .finally(() => {
        target.removeAttribute("disabled");
        target.innerHTML = `Resend Code`;
      });
  };

  const handleSubmitPhoneNumber = (e) => {
    e.preventDefault();

    if (!runValidations()) return;

    if (code.length !== 6)
      return setMessage("warning", "Code should be 6 characters long");

    submitPhoneRef.current.innerHTML = `<span class="fas fa-spinner fa-spin"></span> Loading..`;
    submitPhoneRef.current.setAttribute("disabled", "disabled");

    axiosInstance(token)
      .post(`/accounts/add/phone-number`, {
        mode: modeType,
        phone_number: `+234${phone_number}`,
        option: smsType,
        verification_code: code,
      })
      .then((response) => {
        // pausing the closing of the popup modal
        const show = setInterval(() => {
          setShowEnterNumber(false);
          
          setCartStatus("success");
          setCartMessage(response.data.data.message);
          setShowCartNotify(true);
          clearInterval(show);
        }, 3000);
      })
      .catch((err) => {
        if (err.code === "ERR_BAD_REQUEST") {
          setMessage("warning", err.response.data.message);
        }
      })
      .finally(() => {
        submitPhoneRef.current.removeAttribute("disabled", "disabled");
        submitPhoneRef.current.innerHTML = "Submit";
      });
  };

  useEffect(() => {
    if (resendBlocked <= 0 && typeof resendBlockedInterval === "number") {
      setResendBlocked(0);
      window.clearInterval(resendBlockedInterval);
      return setResendBlockedInterval(null);
    }

    if (resendBlocked > 0 && resendBlockedInterval === null) {
      setResendBlockedInterval(
        window.setInterval(() => {
          setResendBlocked((b) => b - 1000);
        }, 1000)
      );
    }
  }, [resendBlocked, resendBlockedInterval]);

  useEffect(() => {
    let codeCheck = phone_number;

    if (codeCheck && codeSend[codeCheck]) {
      const resendBlockedUntil = new Date(+codeSend[codeCheck]).getTime();

      const currentDate = new Date().getTime();

      if (currentDate < resendBlockedUntil) {
        setResendBlocked(resendBlockedUntil - currentDate);
        setResendBlockedFor(codeCheck);
      }
    }
  }, [phone_number, codeSend, setResendBlocked]);

  useEffect(() => {
    clearMessage();
  }, [modeType, phone_number, code, clearMessage]);

  let resendBlockedDate = null;

  if (resendBlocked) {
    const date = new Date(resendBlocked);
    resendBlockedDate = `${String(date.getMinutes()).padStart(2, "0")}:${String(
      date.getSeconds()
    ).padStart(2, "0")}`;
  }

  return (
    <div className="pop__page">
      <div className="enter__body">
        <div className="close__icon" onClick={handleCloseNumber}>
          <ion-icon name="close-outline"></ion-icon>
        </div>
        <div className="enter__content">
          <p className="enter__text">Enter Phone Number</p>
          <p className="enter__textt">
            Kindly provide your phone number to proceed
          </p>
          <form className="form-input-group form-input-group--1">
            <button type="button">+234</button>
            <input
              name="phone_number"
              type="text"
              placeholder="8012... (Remove the first zero)"
              autoComplete="new-password"
              value={phone_number}
              onChange={(e) => setPhone_number(e.target.value)}
              maxLength={10}
            />
          </form>
        </div>

        <div className="otp-medium mb-medium">
          <h3 className="otp-mediums__heading">
            Receive Verification code Via
          </h3>
          <div className="otp-medium__blocks">
            <div className="otp-medium__block">
              <img src={whatsapp} alt="" className="otp-medium__block-img" />
              <p className="otp-medium__block-name">Whatsapp (Instant)</p>
              <input
                type="checkbox"
                className="checkbox-switch"
                checked={smsType === "whatsapp"}
                onChange={(e) =>
                  setSmsType(e.target.checked ? "whatsapp" : "sms")
                }
              />
            </div>
            <div className="otp-medium__block">
              <img src={sms} alt="" className="otp-medium__block-img" />
              <p className="otp-medium__block-name">SMS (0 - 5 MIN)</p>
              <input
                type="checkbox"
                className="checkbox-switch"
                checked={smsType === "sms"}
                onChange={(e) =>
                  setSmsType(e.target.checked ? "sms" : "whatsapp")
                }
              />
            </div>
          </div>
          <div className="form-inputt-group">
            <input
              type="text"
              placeholder="Verification code"
              autoComplete="new-password"
              value={code}
              onChange={(e) => setCode(e.target.value)}
            />

            {resendBlocked && resendBlockedFor === phone_number ? (
              <button type="button" style={{ opacity: "0.7" }} disabled>
                Resend in {resendBlockedDate}
              </button>
            ) : (
              <button
                onClick={requestCode}
                disabled={!phone_number}
                type="button"
              >
                Request Code
              </button>
            )}
          </div>
        </div>
        {message}
        <div className="submit__phone__number">
          <button
            ref={submitPhoneRef}
            type="submit"
            onClick={handleSubmitPhoneNumber}
            className={`${activateBtn ? "enabled" : "disabled"} submit`}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default EnterNumber;
