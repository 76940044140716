import { useState, useEffect } from "react";
import { axiosInstance } from "../libries";

//components
import Layout from "../containers/Layout";
import Breadcrumb from "../components/Breadcrumb";
import HelpCenter from "../components/HelpCenter";

const RefundPolicy = () => {
  // state to set active tabs
  const [activeHelpTabs, setActiveHelpTabs] = useState("refund");

  const [refund, setRefund] = useState(null);

  // state to track loading state
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axiosInstance()
      .get(`/help-center/refund-policy`)
      .then((resp) => {
        setRefund(resp.data.data.message);
      })
      .catch((err) => {
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    document.title = "ShopNig - Refund Policy";
  }, []);

  return (
    <Layout>
      <Breadcrumb name="Product detail >" />
      <div className="contain">
        <div className="help">
          <HelpCenter
            activeHelpTabs={activeHelpTabs}
            setActiveHelpTabs={setActiveHelpTabs}
          />
          {loading && (
            <div className="loading__spin__address">
              <span className="loader"></span>
            </div>
          )}
          {!loading &&
            refund?.map((data, i) => (
              <div className="refund" key={i}>
                <h5 className="refund__title">{data.title}</h5>
                <div className="refund__body">
                  {data.description.split("\n\n").map((item, i) => (
                    <p className="refund__text" key={i}>
                      {item}
                    </p>
                  ))}
                </div>
              </div>
            ))}
        </div>
      </div>
    </Layout>
  );
};

export default RefundPolicy;
