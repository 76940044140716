const Explore = ({ prodDetailsBanners }) => {
  return (
    <div className="explore__body">
      <div className="explore__part">
        {prodDetailsBanners?.bottom_right_banner && (
          <div>
            <a
              href={`/categories?categories=${prodDetailsBanners?.bottom_left_banner?.category_id}`}
            >
              <img
                src={prodDetailsBanners?.bottom_left_banner?.img}
                alt="product banner"
                className="explore__banner"
              />
            </a>
          </div>
        )}

        {prodDetailsBanners?.bottom_right_banner && (
          <div>
            <a
              href={`/categories?categories=${prodDetailsBanners?.bottom_right_banner.category_id}`}
            >
              <img
                src={prodDetailsBanners?.bottom_right_banner?.img}
                alt="product banner"
                className="explore__banner"
              />
            </a>
          </div>
        )}
      </div>
    </div>
  );
};

export default Explore;
