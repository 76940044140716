const Ratings = ({ data, averageRating, setOpenRatings }) => {
  if (data.length > 0 && averageRating !== 0) {
    return (
      <div className="verified">
        <div className="verified__page">
          <div className="verified__rating">
            <h4 className="verified__text">VERIFIED RATING</h4>
            <div className="verified__rate">
              <h3 className="verified__fig">{Math.round(averageRating)}/5</h3>
              {[...Array(5)].map((star, index) => {
                const showRating = index + 1;
                return (
                  <span
                    className={`${
                      parseInt(Math.round(averageRating)) >= showRating
                        ? "yellow"
                        : "grey"
                    } starProd`}
                    key={index}
                  >
                    <ion-icon name="star"></ion-icon>
                  </span>
                );
              })}
            </div>
          </div>
        </div>
        <div className="customer">
          <div className="customer__text">
            <h4 className="customer__review__heading">Customer's Review ({data.length})</h4>
            {data.length > 5 && (
              <span
                className="see__all__rating"
                onClick={() => setOpenRatings(true)}
              >
                see all ratings
              </span>
            )}
          </div>
          <div className="customer__review">
            {data?.slice(0, 5).map((itm, id) => (
              <div className="customer__comment" key={id}>
                {[...Array(5)].map((star, index) => {
                  const showRating = index + 1;
                  return (
                    <span
                      className={`${
                        parseInt(itm?.rating) >= showRating ? "yellow" : "grey"
                      } starProd`}
                      key={index}
                    >
                      <ion-icon name="star"></ion-icon>
                    </span>
                  );
                })}
                <p className="customer__view">{itm.review_details}</p>
                <p className="customer__name">
                  {new Date(itm.updated_at).toLocaleDateString("en-us", {
                    year: "numeric",
                    month: "numeric",
                    day: "numeric",
                  })}{" "}
                  by {itm.user_who_rated}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="verified">
        <p style={{ fontSize: "1.6rem" }}>No review for product yet!</p>
      </div>
    );
  }
};

export default Ratings;
