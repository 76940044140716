import { useCopyToClipboard } from "../hooks/useCopyToClipboard";
import { useSelector } from "react-redux";

import bank from "../assets/imgs/bank-outline.svg";

const InsufficientBalance = ({ close }) => {
  const user = useSelector((state) => state.auth.userDetails);
  const userBankDetails = useSelector((state) => state.auth.userDetails.bank);
  const isVerified = useSelector(
    (state) => state.auth.userDetails.verification
  );

  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

  function generateString(length) {
    let result = "";
    const charLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charLength));
    }

    return result;
  }
  const randomCharacters = generateString(50);

  // 👇 Using our custom hook
  const [copyToClipboard, copyResult] = useCopyToClipboard();

  const handleClickCopy = (accNum) => {
    copyToClipboard(accNum);
  };

  return (
    <div className="pop__page">
      <div className="insufficient">
        <div className="insufficient__content">
          <h3 className="opps">Oops!</h3>
          <p className="insuff">Insufficient balance</p>
          <p className="fund">
            Kindly fund your account or choose another payment mode
          </p>
        </div>

        <div className="bank__details">
          <div className="bank">
            <img src={bank} alt="bank icon" />
            <span>Providus Bank</span>
          </div>
          <div className="account__num">
            {userBankDetails.account_number ? (
              <>
                <span>{userBankDetails.account_number}</span>
                <button
                  className="copy"
                  onClick={() =>
                    handleClickCopy(userBankDetails.account_number)
                  }
                >
                  Copy
                  <ion-icon name="copy-outline"></ion-icon>
                </button>
              </>
            ) : (
              <div className="verify__container">
                <span>Verify your account to get an account number</span>
                {(isVerified.status === null ||
                  isVerified.is_verified === -1) && (
                  <mati-button
                    clientid="642a8eae9c002d001a503ede"
                    flowId="64e72ce350ec62001bd20f99"
                    metadata={`{"email": "${user.email}", "id": "${user.slug}", "hash": "${randomCharacters}"}`}
                    color="#314706"
                  />
                )}
              </div>
            )}
          </div>
          <span className="wallet__info">
            This works like a regular bank account. You will be credited
            instantly
          </span>

          <div className="copied">
            {copyResult?.status === "success" && <i>Copied Successfully!</i>}
          </div>
        </div>

        <div className="popup__butn">
          <button className="popup__close" onClick={close}>
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default InsufficientBalance;
