import Image from "./Image";

// images - bannerCategory
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const BannerLoader = () => {
  return (
    <section className="container banners">
      <div>
        <div className="placeholder"></div>
      </div>
      <div>
        <div className="placeholder"></div>
      </div>
      <div>
        <div className="placeholder"></div>
      </div>
      <div>
        <div className="placeholder"></div>
      </div>
    </section>
  );
};

export const Banner = () => {
  const navigate = useNavigate();
  const { banners } = useSelector((state) => state.cache);

  let left_banner, right_banner_top, right_banner_bottom, middle_banner_sliders;

  if (banners) {
    ({
      left_banner,
      right_banner_top,
      right_banner_bottom,
      middle_banner_sliders,
    } = banners);
  }
  // const [activeSlide, setActiveSlide] = useState(
  //   middle_banner_sliders && middle_banner_sliders[0]?.id
  // );

  const [trigger, setTrigger] = useState(false);

  const imagesRef = useRef([]);
  const sliderDotsRef = useRef([]);

  const goToSlide = (slide) => {
    const slides = imagesRef.current;

    slides.forEach((s, i) => {
      if (!s) return;

      s.style.transform = `translateX(${100 * (i - slide)}%)`;
    });

    // slide && setActiveSlide(slide)

    const sliderDots = sliderDotsRef.current;

    sliderDots.forEach((s, i) => {
      if (!s) return;

      if (slide === i) {
        s.classList.add("active-slide");
      } else {
        s.classList.remove("active-slide");
      }
    });
  };

  useEffect(() => {
    if (!trigger) return;

    const slides = imagesRef.current;

    let curSlide = 0;
    const maxSlide = slides.length;

    const nextSlide = () => {
      if (curSlide === maxSlide - 1) {
        curSlide = 0;
      } else {
        curSlide++;
      }
      goToSlide(curSlide);
    };

    // Go to next slide every 3 seconds
    setInterval(nextSlide, 3000);
  }, [trigger]);

  if (!banners) return <BannerLoader />;
  else
    return (
      <section className="container banners">
        <div>
          <Image
            src={left_banner?.img}
            alt="banners"
            clickImg={() =>
              navigate(`/categories?categories=${left_banner?.category_id}`)
            }
          />
        </div>
        <div className="banner-middle">
          {middle_banner_sliders?.map((middle, i) => (
            <Image
              key={i}
              src={middle.img}
              alt="banners"
              className="banner__img"
              refFunc={(el) => {
                imagesRef.current[i] = el;
                i === middle_banner_sliders.length - 1 && setTrigger(true);
              }}
              clickImg={() =>
                navigate(`/categories?categories=${middle?.category_id}`)
              }
            />
          ))}
          <span className="banner__slider-radio">
            {middle_banner_sliders?.map((_, i) => (
              <span
                key={i}
                ref={(el) => (sliderDotsRef.current[i] = el)}
                className="banner__radio-banners"
                onClick={() => {
                  goToSlide(i);
                }}
              ></span>
            ))}
          </span>
        </div>

        <div>
          <Image
            src={right_banner_top?.img}
            alt=""
            clickImg={() =>
              navigate(
                `/categories?categories=${right_banner_top?.category_id}`
              )
            }
          />
        </div>
        <div>
          <Image
            src={right_banner_bottom?.img}
            alt=""
            clickImg={() =>
              navigate(
                `/categories?categories=${right_banner_bottom?.category_id}`
              )
            }
          />
        </div>
      </section>
    );
};

export const BannerCategory = ({ catBanners }) => {
  const navigate = useNavigate();

  return (
    <div className="banner">
      <div className="container">
        <div className="bannerCat">
          <div className="bannerCat__wrapper">
            {catBanners?.top_banner.map((banner) => (
              <div
                className="bannerCat__itm"
                key={banner.id}
                onClick={() =>
                  navigate(`/categories?categories=${banner.category_id}`)
                }
              >
                <img
                  src={banner?.img}
                  alt="banners"
                  className="categories__head__img"
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
