const Remove = ({ handleNo, handleYes, title, subtitle, btn1, btn2 }) => {
  return (
    <div className="pop__page">
      <div className="remove__body">
        <div className="remove__content">
          <p className="remove__text">{title}</p>
          <p className="remove__textt">
            {subtitle}
          </p>
        </div>
        <div className="confirm__detail">
          <button className="cancel__confirm" onClick={handleNo}>
            {btn1}
          </button>
          <button className="remove__confirm" onClick={handleYes}>
            {btn2}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Remove;
