import { useRef, useEffect } from "react";

const PinGroup = ({
  className,
  numInputs,
  pin: inpPin,
  handler,
  dontFocus = true,
  type = "password",
}) => {
  const inputsRef = useRef([]);

  const pin = new Array(numInputs).fill(null).map((_, i) => inpPin[i] || "");

  useEffect(() => {
    const emptyIndex = pin.findIndex((code) => !code);
    if ((emptyIndex || emptyIndex === 0) && inputsRef.current[emptyIndex]) {
      if (dontFocus && emptyIndex === 0) return;
      inputsRef.current[emptyIndex].focus();
    }
  }, [inpPin, pin, dontFocus]);

  useEffect(() => {
    inputsRef.current.forEach((input, i) => {
      input.addEventListener("keyup", (e) => {
        if (!e.target.value && e.key === "Backspace" && i > 0) {
          inputsRef.current[i - 1].focus();
        }
      });
    });
  });

  return (
    <>
      {new Array(numInputs).fill(null).map((_, i) => {
        return (
          <input
            key={i}
            type={type}
            className={className || "pin-input"}
            maxLength={1}
            value={pin[i]}
            onChange={(e) => {
              handler(
                pin.map((v, j) => (i === j ? e.target.value : v)).join("")
              );
            }}
            ref={(el) => (inputsRef.current[i] = el)}
            autoComplete="new-password"
          />
        );
      })}
    </>
  );
};

export default PinGroup;
