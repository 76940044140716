import React, { useEffect, useState } from "react";
import { axiosInstance } from "../libries";
import { useDispatch, useSelector } from "react-redux";
import Product, { ProductLoader } from "../components/Product";
import Empty from "../components/Empty";

const RecentlyViewed = () => {
  const dispatch = useDispatch();
  const userToken = useSelector((state) => state.auth.accessToken);

  // showing alert states
  const [alertMsg, setAlertMsg] = useState("");

  const [networkError, setNetworkError] = useState(false);

  const [recentlyViewed, setRecentlyViewed] = useState([]);

  // state to track loading state
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (userToken)
      axiosInstance()
        .get(`/products/recently/viewed`)
        .then((resp) => {
          let viewed = resp.data.data.viewed_product;

          const updatedViewed = viewed.map((el) => ({
            ...el.product,
            images: el.images,
          }));

          setRecentlyViewed(updatedViewed);

          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          if (err.code === "ERR_BAD_REQUEST") {
            setAlertMsg(err.response.data.message);
          } else {
            setNetworkError(true);
            setAlertMsg(err.message);
          }
        });
  }, [userToken, dispatch]);

  useEffect(() => {
    document.title = "ShopNig - Recently Viewed";
  }, []);

  return (
    <div className="recentlyViewed">
      <div className="reviews__head">
        <p>Recently Viewed</p>
      </div>
      <div className="product">
        {!loading &&
          !networkError &&
          recentlyViewed &&
          !recentlyViewed.length && (
            <Empty emptyTitle="You have no recently viewed product yet!" />
          )}
        {!loading && networkError && (
          <Empty
            emptyTitle={alertMsg}
            emptySubTitle="Something went wrong! TRY AGAIN.."
            network={true}
          />
        )}
        <div className="product__body">
          {loading
            ? new Array(20).fill(null).map((_, i) => <ProductLoader key={i} />)
            : recentlyViewed.length
            ? recentlyViewed.map((itm) => (
                <Product
                  data={itm}
                  key={itm.id}
                  discount={true}
                  isNew={false}
                />
              ))
            : null}
        </div>
      </div>
    </div>
  );
};

export default RecentlyViewed;
