import { useState, useEffect } from "react";
import { axiosInstance } from "../libries";

//for components
import Layout from "../containers/Layout";
import Breadcrumb from "../components/Breadcrumb";
import HelpCenter from "../components/HelpCenter";

const AboutUs = () => {
  // state to set active tabs
  const [activeHelpTabs, setActiveHelpTabs] = useState("about");

  const [aboutUs, setAboutUs] = useState(null);

  // state to track loading state
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axiosInstance()
      .get(`/help-center/about-us`)
      .then((resp) => {
        setAboutUs(resp.data.data.message);
      })
      .catch((err) => {})
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    document.title = "ShopNig - About Us";
  }, []);

  return (
    <Layout>
      <Breadcrumb name="Product detail >" />
      <div className="container">
        <div className="about">
          <HelpCenter
            activeHelpTabs={activeHelpTabs}
            setActiveHelpTabs={setActiveHelpTabs}
          />
          {loading && (
            <div className="loading__spin__address">
              <span className="loader"></span>
            </div>
          )}
          {!loading &&
            aboutUs?.map((data, i) => (
              <div className="about__wrap" key={i}>
                <h5 className="about__title">{data.title}</h5>
                <div className="about__part">
                  <div className="know__img">
                    <img src={data.image} alt="" className="img__pics" />
                  </div>
                  <div className="know__about">
                    {data.description.split("\n\n").map((item, i) => (
                      <p className="textt" key={i}>
                        {item}
                      </p>
                    ))}
                      <button className="btn btn-primary" id="open_live_chat">
                        Contact Us
                      </button>
                  </div>
                </div>
                <div className="about__features">
                  <h2 className="feature__text">
                    <span>{data.features_title}</span>
                  </h2>
                  <div className="feature__part">
                    {data.features.map((feature, i) => (
                      <div className="feature__listed" key={i}>
                        <div className="list__icons">
                          <img src={feature.icon} alt="" className="iconn" />
                        </div>
                        <div>
                          <p className="list__title">{feature.title}</p>
                          <p className="list__text">{feature.description}</p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </Layout>
  );
};

export default AboutUs;
