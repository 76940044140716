import { useEffect, useState } from "react";
import { axiosInstance } from "../libries";

import DashboardHeader from "../components/DashboardHeader";
import Empty from "../components/Empty";

const RankingBadge = () => {
  const [ranks, setRanks] = useState(null);
  // state to track loading state
  const [loading, setLoading] = useState(true);

  // showing alert states
  const [alertMsg, setAlertMsg] = useState("");

  const [networkError, setNetworkError] = useState(false);

  useEffect(() => {
    axiosInstance()
      .get(`/get-badges-list`)
      .then((resp) => {
        const response = resp.data.data.badges.data;
        setRanks(response);
      })
      .catch((err) => {
        if (err.code === "ERR_BAD_REQUEST") {
          setAlertMsg(err.response.data.message);
        } else {
          setNetworkError(true);
          setAlertMsg(err.message);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);
  return (
    <div className="">
      <DashboardHeader title="Ranking Badge Explanation" isIcon="false" />
      {loading && (
        <div className="loading__spin__address">
          <span className="loader"></span>
        </div>
      )}
      {!loading && networkError && (
          <Empty
            emptyTitle={alertMsg}
            emptySubTitle="Something went wrong! TRY AGAIN.."
            network={true}
          />
        )}
      {!loading && ranks && (
        <div className="ranking">
          <div className="ranking__body">
            {ranks?.map((rank) => (
              <div className="ranking__grades" key={rank.id}>
                <img src={rank.icon_url} alt="bronze icon" className="icon" />
                <p className="text">{rank.name}:</p>
                <span className="ranks">
                  {rank.min_order} - {rank.max_order} Orders
                </span>
              </div>
            ))}
          </div>

          <div className="ranking__benefits">
            <h5 className="benefitt">Benefits</h5>
            {ranks?.map((rank) => (
              <div className="ranking__list">
                <div className="listed">
                  <div className="listed__ranks">
                    <img src={rank.icon_url} alt="bronze icon" />
                    <p className="text">{rank.name}:</p>
                  </div>
                </div>
                <div className="listed__info">
                  <p className="infos">{rank.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default RankingBadge;
