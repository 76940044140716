import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  addCartQuantity,
  removeCartQuantiy,
  removeCartItem,
  clearCarts,
  addCartItem,
} from "../store/slices/cartSlice";
import { axiosInstance, currency_formatter } from "../libries";

import Remove from "./RemovePopup";
import CartNotification from "./CartNotification";

import EnterNumber from "./EnterNumber";
import SignLogin from "./SignLogin";

const AvailableCart = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const usertoken = useSelector((state) => state.auth.accessToken);
  const user = useSelector((state) => state.auth.userDetails);
  const cart = useSelector((state) => state.cart.carts);

  const [closePopup, setClosePopup] = useState(false);

  const myCurrentLocation = window.location.pathname;

  // state to show the notification
  const [showCartNotify, setShowCartNotify] = useState(false);
  const [cartStatus, setCartStatus] = useState("");
  const [cartMessage, setCartMessage] = useState("");

  let total = cart.map((itm) => {
    if (itm.variation_info?.length) {
      return itm.variation_info.reduce((sum, el) => sum + el.total, 0);
    } else return itm.total;
  });
  const [activatePay, setActivatePay] = useState(false);

  const addQuantity = (id, var_id) => {
    dispatch(addCartQuantity({ ...id, variation_id: var_id }));

    let addCartwithOrWithoutVariation = [];
    id.variation_info !== null
      ? addCartwithOrWithoutVariation.push({
          product_id: id.id,
          variation: id.variation_info?.map((variatn) => ({
            id: variatn.id,
            quantity: variatn.quantity + 1,
          })),
        })
      : addCartwithOrWithoutVariation.push({
          product_id: id.id,
          quantity: id.quantity + 1,
        });

    if (usertoken)
      axiosInstance(usertoken)
        .post("/carts/add", {
          product_orders: addCartwithOrWithoutVariation,
        })
        .then(async (_resp) => {})
        .catch((_err) => {});
  };

  const removeQuantity = (id, var_id) => {
    dispatch(removeCartQuantiy({ ...id, variation_id: var_id }));

    let addCartwithOrWithoutVariation = [];
    id.variation_info !== null
      ? addCartwithOrWithoutVariation.push({
          product_id: id.id,
          variation: id.variation_info?.map((variatn) => ({
            id: variatn.id,
            quantity: variatn.quantity - 1,
          })),
        })
      : addCartwithOrWithoutVariation.push({
          product_id: id.id,
          quantity: id.quantity - 1,
        });

    if (usertoken)
      axiosInstance(usertoken)
        .post("/carts/add", {
          product_orders: addCartwithOrWithoutVariation,
        })
        .then(async (_resp) => {})
        .catch((_err) => {});
  };

  const removeCart = (id, var_id) => {
    dispatch(removeCartItem({ ...id, variation_id: var_id }));

    if (usertoken)
      axiosInstance(usertoken)
        .post("/carts/remove", {
          product_id: id.id,
        })
        .then((resp) => {
          if (resp.data.status) {
            dispatch(removeCartItem({ ...id, variation_id: var_id }));
          }
        })
        .catch((err) => {});
    else dispatch(removeCartItem({ ...id, variation_id: var_id }));
  };

  const [delProductId, setDelProductId] = useState(null);

  // popup for confirm delete
  const [confirmDelete, setConfirmDelete] = useState(false);

  const [showEnterNumber, setShowEnterNumber] = useState(false);

  // state for empty cart
  const [empty, setEmpty] = useState(false);

  // function to close confirm delete popup
  const handleYes = () => {
    if (!empty) {
      if (delProductId?.variation_info === null) {
        removeCart(delProductId);
      } else removeCart(delProductId.id, delProductId.var_id);
      setConfirmDelete(false);

      let remove_id =
        delProductId.id.variation_info === null
          ? { product_id: delProductId.id }
          : { product_id: delProductId.id.id, combo_id: delProductId.var_id };

      if (usertoken)
        axiosInstance(usertoken)
          .post("/carts/remove", remove_id)
          .then(async (_resp) => {
            const resp_1 = await axiosInstance(usertoken).get("/carts");
            const allCarts = resp_1.data.data.cart;
            if (!allCarts) {
              dispatch(clearCarts());
            }
            if (allCarts) {
              allCarts.map((cart) => {
                let allVariation = [];

                if (cart.variation !== null) {
                  cart.variation.forEach((cartVar) => {
                    allVariation.push({
                      amount: cartVar.amount,
                      id: cartVar.combo_id,
                      quantity: cartVar.quantity,
                      total: Number(cartVar.amount * cartVar.quantity),
                      stock_count: cartVar.in_stock,
                      variations: cartVar.variations,
                    });
                  });
                }
                return dispatch(
                  addCartItem({
                    id: cart.product.id,
                    p_slug: cart.p_slug,
                    name: cart.product.name,
                    color: cart.product.color,
                    quantity: cart.quantity,
                    src: cart.images,
                    brand: "",
                    price: Number(cart.product.amount),
                    description: cart.product.description,
                    total: Number(cart.product.amount) * cart.quantity,
                    variation_info: cart.product.is_fixed ? null : allVariation,
                    stock_count:
                      cart.product.is_fixed === 1
                        ? cart.product.stock_count
                        : cart.product.in_stock,
                    weight: cart.product.weight,
                  })
                );
              });
            }
          })
          .catch((err) => {});
    } else {
      dispatch(clearCarts());
      setEmpty(false);
      // sending endpoint to empty cart here

      if (usertoken)
        axiosInstance(usertoken)
          .post("/carts/remove/all")
          .then((_resp) => {})
          .catch((err) => {});
    }
  };

  const handleNo = () => {
    !empty ? setConfirmDelete(false) : setEmpty(false);
  };

  const handleConfirmCartItem = (id, var_id) => {
    setConfirmDelete(true);
    if (id.variation_info === null) {
      setDelProductId(id);
    } else setDelProductId({ id, var_id });
  };

  const handleEmptyCart = () => {
    setEmpty(true);
  };

  // useEffect to disable clicking on checkout when a product is out of stock or stock count is less than the quantity
  useEffect(() => {
    let result = [];
    cart.forEach((cartItm) => {
      if (cartItm.variation_info === null) {
        if (
          cartItm.stock_count === 0 ||
          cartItm.quantity > cartItm.stock_count
        ) {
          result.push(true);
        }
      } else if (cartItm.variation_info !== null) {
        cartItm.variation_info.forEach((varItem) => {
          if (
            varItem.stock_count === 0 ||
            varItem.quantity > varItem.stock_count
          ) {
            result.push(true);
          }
        });
      }
    });
    if (result.length) {
      setActivatePay(true);
    } else {
      setActivatePay(false);
    }
  }, [cart]);

  const handleGoToCheckout = () => {
    if (user && user.phone_number) {
      navigate("/checkout");
    } else if (user && !user.phone_number) {
      setShowEnterNumber(true);
    } else {
      document.body.style.overflow = "hidden";
      setClosePopup(true);
    }
  };

  const handleCloseNumber = () => {
    setShowEnterNumber(false);
  };

  useEffect(() => {
    if (!closePopup) document.body.style.overflow = "unset";
  }, [closePopup]);

  return (
    <>
      <div className="cart">
        <h3 className="items">Items in shopping cart</h3>
        <div className="cart__troy">
          <div className="cart__itms">
            {cart.map((itm, id) =>
              !itm.variation_info?.length ? (
                <div
                  className={`${
                    itm.stock_count === 0 && "text__gray__outOfStock"
                  } cart__itm placholder`}
                  key={id}
                >
                  <div className="cart__prod">
                    <div className="imgWrap">
                      <Link to={`/product-details/${itm.p_slug}`}>
                        <img src={itm.src?.image_url} alt="crt" />
                      </Link>
                    </div>
                    <div className="imgCaption">
                      <h3 className="cap__name">
                        {itm.name.length > 30
                          ? itm.name.slice(0, 30) + "..."
                          : itm.name}
                      </h3>

                      <div className="cart__total__mobile cap__name">
                        {itm.stock_count === 0 ? (
                          <span className="out__of__stock">Out of Stock</span>
                        ) : itm.quantity > itm.stock_count ? (
                          <span className="out__of__stock">
                            {`${itm.stock_count} unit(s) left`}
                          </span>
                        ) : (
                          <p className="price__text">
                            {currency_formatter(itm.total)}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="cart__qualities">
                    <div className="cart__price">
                      <p className="price__text">
                        {currency_formatter(itm.price)}
                      </p>
                    </div>
                    <div className="cart__quantity">
                      <div className="quan__control">
                        <button
                          className={`${
                            itm.quantity === 1 ? "disableBtn" : "subtract__btn"
                          }`}
                          onClick={() => removeQuantity(itm)}
                        >
                          -
                        </button>
                        <div>
                          <span className="price__text">{itm.quantity} </span>
                        </div>
                        <button
                          className={`${
                            itm.quantity >= itm.stock_count
                              ? "disableBtn"
                              : "add__btn"
                          }`}
                          onClick={() => addQuantity(itm)}
                        >
                          +
                        </button>
                      </div>
                    </div>
                    <div className="cart__total">
                      {itm.stock_count === 0 ? (
                        <span
                          style={{
                            color: "red",
                            fontSize: "14px",
                            fontWeight: 800,
                          }}
                        >
                          Out of Stock
                        </span>
                      ) : itm.quantity > itm.stock_count ? (
                        <span
                          style={{
                            color: "red",
                            fontSize: "14px",
                            fontWeight: 600,
                          }}
                        >
                          {`${itm.stock_count} unit(s) left`}
                        </span>
                      ) : (
                        <p className="price__text">
                          {currency_formatter(itm.total)}
                        </p>
                      )}
                    </div>

                    <div className="cart__del">
                      <button
                        className="cancel__btn"
                        onClick={() => handleConfirmCartItem(itm)}
                      >
                        <ion-icon name="trash-outline"></ion-icon>
                      </button>
                    </div>
                  </div>
                </div>
              ) : (
                <React.Fragment key={id}>
                  {itm.variation_info.map((varInfo, i) => (
                    <div
                      className={`${
                        varInfo.stock_count === 0 && "text__gray__outOfStock"
                      } cart__itm placholder`}
                      key={i}
                    >
                      <div className="cart__prod">
                        <div className="imgWrap">
                          <Link to={`/product-details/${itm.p_slug}`}>
                            <img src={itm.src.image_url} alt="crt" />
                          </Link>
                        </div>
                        <div className="imgCaption">
                          <h3 className="cap__name">
                            {itm.name.length > 30
                              ? itm.name.slice(0, 30) + "..."
                              : itm.name}
                          </h3>
                          {varInfo.variations?.length
                            ? varInfo.variations.map((itm, id) => (
                                <React.Fragment key={id}>
                                  <p className="cap__type">
                                    <span className="typee">{itm}</span>
                                  </p>
                                </React.Fragment>
                              ))
                            : null}
                          <div className="cart__total__mobile cap__name">
                            {varInfo.stock_count === 0 ? (
                              <span className="out__of__stock">
                                Out of Stock
                              </span>
                            ) : varInfo.quantity > varInfo.stock_count ? (
                              <span className="out__of__stock">
                                {`${varInfo.stock_count} unit(s) left`}
                              </span>
                            ) : (
                              <p className="price__text">
                                {currency_formatter(
                                  varInfo.quantity * varInfo.amount
                                )}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="cart__qualities">
                        <div className="cart__price">
                          <p className="price__text">
                            {currency_formatter(varInfo.amount)}
                          </p>
                        </div>
                        <div className="cart__quantity">
                          <div className="quan__control">
                            <button
                              className={`${
                                varInfo.quantity === 1
                                  ? "disableBtn"
                                  : "subtract__btn"
                              }`}
                              onClick={() => removeQuantity(itm, varInfo.id)}
                            >
                              -
                            </button>
                            <div>
                              <span className="price__text">
                                {varInfo.quantity}
                              </span>
                            </div>
                            <button
                              className={`${
                                varInfo.quantity >= varInfo.stock_count
                                  ? "disableBtn"
                                  : "add__btn"
                              }`}
                              onClick={() => addQuantity(itm, varInfo.id)}
                            >
                              +
                            </button>
                          </div>
                        </div>
                        <div className="cart__total">
                          {varInfo.stock_count === 0 ? (
                            <span
                              style={{
                                color: "red",
                                fontSize: "14px",
                                fontWeight: 600,
                              }}
                            >
                              Out of Stock
                            </span>
                          ) : varInfo.quantity > varInfo.stock_count ? (
                            <span
                              style={{
                                color: "red",
                                fontSize: "14px",
                                fontWeight: 600,
                              }}
                            >
                              {`${varInfo.stock_count} unit(s) left`}
                            </span>
                          ) : (
                            <p className="price__text">
                              {currency_formatter(
                                varInfo.quantity * varInfo.amount
                              )}
                            </p>
                          )}
                        </div>
                        <div className="cart__del">
                          <button
                            className="cancel__btn"
                            onClick={() =>
                              handleConfirmCartItem(itm, varInfo.id)
                            }
                          >
                            <ion-icon name="trash-outline"></ion-icon>
                          </button>
                        </div>
                      </div>
                    </div>
                  ))}
                </React.Fragment>
              )
            )}

            <div className="empty__cart__head">
              <div className="empty__my__cart" onClick={handleEmptyCart}>
                Empty Cart
              </div>
            </div>
          </div>

          <div className="order">
            <div className="order__part">
              <h4 className="order__text">ORDER SUMMARY</h4>
              <div className="summary__order">
                <div className="order__summary">
                  <div className="summary__details">
                    <h3 className="coupon">Subtotal</h3>
                  </div>
                  <div className="coupon__btn">
                    <p className="coupon">
                      {currency_formatter(
                        total.reduce((curr, val) => curr + val, 0)
                      )}
                    </p>
                  </div>
                </div>
              </div>
              <div className="order__summary">
                <div className="order__details">
                  <h3 className="coupon__grand">Total</h3>
                </div>
                <div className="coupon__btn">
                  <p className="coupon__grand">
                    {currency_formatter(
                      total.reduce((curr, val) => curr + val, 0)
                    )}
                  </p>
                </div>
              </div>
              <div className="order__check">
                <button
                  className={`${
                    activatePay ? "disableCheckoutt" : "checkoutt"
                  }`}
                  disabled={activatePay}
                  onClick={handleGoToCheckout}
                >
                  CHECKOUT
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showEnterNumber && (
        <EnterNumber
          handleCloseNumber={handleCloseNumber}
          setShowCartNotify={setShowCartNotify}
          setCartMessage={setCartMessage}
          setCartStatus={setCartStatus}
          setShowEnterNumber={setShowEnterNumber}
        />
      )}
      {showCartNotify && (
        <CartNotification
          cartMessage={cartMessage}
          cartStatus={cartStatus}
          setShowCartNotify={setShowCartNotify}
        />
      )}
      {confirmDelete && (
        <Remove
          handleNo={handleNo}
          handleYes={handleYes}
          title="Remove from Cart"
          subtitle="Do you want to remove this item from your cart?"
          btn1="Cancel"
          btn2="Remove"
        />
      )}
      {empty && (
        <Remove
          handleNo={handleNo}
          handleYes={handleYes}
          title="Empty Cart"
          subtitle="Do you want to empty your cart?"
          btn1="Cancel"
          btn2="Yes"
        />
      )}
      {closePopup && (
        <SignLogin
          setClosePopup={setClosePopup}
          closePopup={closePopup}
          myCurrentLocation={myCurrentLocation}
        />
      )}
    </>
  );
};

export default AvailableCart;
