import { useEffect } from "react";
import { axiosInstance } from "../libries";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setBestBrands } from "../store/slices/cacheSlice";
import Image from "./Image";

const BestBrand = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // getting best brand from global store
  const bestBrands = useSelector((state) => state.cache.bestBrands);

  useEffect(() => {
    axiosInstance()
      .get(`/brands/best`)
      .then((res) => {
        const bestBrandsResp = res.data.data.product;
        dispatch(setBestBrands(bestBrandsResp));
      })
      .catch((_err) => {});
  }, [dispatch]);

  return (
    <section className="best-brands">
      <div className="container best-brands__container">
        {bestBrands && (
          <h3 className="best-brands__heading">
            Your best brands are on ShopNig
          </h3>
        )}
        {bestBrands && (
          <div className="best-brands__logos">
            <div className="best-brands__logo-slide">
              {bestBrands.map((itm, i) => (
                <Image
                  src={itm.image_url}
                  alt={itm.name}
                  className="best-brands__brand-img"
                  clickImg={() => navigate(`/categories?brands=${itm.id}`)}
                  key={i}
                />
              ))}
              {bestBrands.map((itm, i) => (
                <Image
                  src={itm.image_url}
                  alt={itm.name}
                  className="best-brands__brand-img"
                  clickImg={() => navigate(`/categories?brands=${itm.id}`)}
                  key={i}
                />
              ))}
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default BestBrand;
