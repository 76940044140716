import { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import axios from "axios";

import { baseUrl } from "../libries";

import { useSelector } from "react-redux";

// images
import logo from "../assets/new-imgs/shopnig-logoo.svg";
import authphoto from "../assets/new-imgs/auth-banner.svg";
import useAlert from "../hooks/useAlert";
import authbg from "../assets/new-imgs/auth-dark.svg";
import logodark from "../assets/new-imgs/shopnig-darklogo.svg";

import PinGroup from "../components/PinGroup";

const ResetPassword = () => {
  const navigate = useNavigate();
  const submitBtnRef = useRef();
  const uiMode = useSelector((state) => state.app.uiMode);

  // state to get login inputs
  const [userLoginDetails, setUserLoginDetails] = useState({
    otp: "",
    password: "",
    confirm_password: "",
  });

  // state to show password or hide password
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [hideBtn, setHideBtn] = useState(false);

  const [message, setMessage, clearMessage] = useAlert();

  const loginUser = (e) => {
    e.preventDefault();

    if (!activateLogin) return setMessage("warning", "Fill in all fields");

    submitBtnRef.current.innerHTML = `<span class="fas fa-spinner fa-spin"></span> Processing`;
    submitBtnRef.current.setAttribute("disabled", "disabled");

    axios({
      url: `${baseUrl}/reset/password`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify({
        code: userLoginDetails.otp,
        password: userLoginDetails.password,
        password_confirmation: userLoginDetails.confirm_password,
      }),
    })
      .then((response) => {
        if (response.data.status) {
          setMessage("success", response.data.message);

          setHideBtn(true);

          const interval = window.setInterval(() => {
            navigate("/login");
            window.clearInterval(interval);
          }, 4000);
        } else {
          setMessage("warning", response.data.message);
        }
      })
      .catch((err) => {
        if (err.code === "ERR_BAD_REQUEST") {
          setMessage("warning", err.response.data.message);
        } else {
          setMessage("error", err.message);
        }
      })
      .finally(() => {
        submitBtnRef.current.removeAttribute("disabled", "disabled");
        submitBtnRef.current.innerHTML = "Reset password";
      });
  };

  const handleChangeUserInputs = (e) => {
    const { name, value } = e.target;

    setUserLoginDetails({
      ...userLoginDetails,
      [name]: value,
    });
  };

  useEffect(() => {
    clearMessage();
  }, [
    userLoginDetails.password,
    userLoginDetails.confirm_password,
    userLoginDetails.otp,
    clearMessage,
  ]);

  const activateLogin =
    userLoginDetails.password &&
    userLoginDetails.password.length >= 8 &&
    userLoginDetails.password.length <= 16 &&
    userLoginDetails.confirm_password &&
    userLoginDetails.otp.length === 6 &&
    userLoginDetails.password === userLoginDetails.confirm_password;

    useEffect(() => {
      document.title = "ShopNig - Reset Password";
    }, []);

  return (
    <>
      <div className="auth__container">
        <div className="auth__photo">
          <img src={uiMode === "light" ? authphoto : authbg} alt="login" />
        </div>
        <form
          onSubmit={loginUser}
          className="auth__main auth__main--1"
          style={{
            // minHeight: "80vh",
            paddingBottom: "12.8rem",
          }}
        >
          <div className="auth__img-block">
            <a href="/">
              <img
                src={uiMode === "light" ? logo : logodark}
                alt=""
                className={["auth__img", "cursor-pointer"].join(" ")}
              />
            </a>
          </div>
          <div className="auth__card">
            <div className="auth__card-main">
              <div className="auth__header" style={{ marginBottom: "2.4rem" }}>
                <h3 className="auth__heading">Reset password</h3>
                <p className="auth__subheading">
                  Reset password OTP has been sent to your email address or
                  phone number. To reset password, create a new password and
                  input your OTP to complete the process.
                </p>
              </div>
              <div className="auth__form" style={{ gap: "2.2rem" }}>
                <div className="form-group">
                  <label className="form-label">New Password</label>
                  <div className="input-group input-group--1">
                    <input
                      name="password"
                      type={showPassword ? "text" : "password"}
                      value={userLoginDetails.password}
                      placeholder="Enter new password"
                      autoComplete="new-password"
                      onChange={handleChangeUserInputs}
                    />
                    <button
                      type="button"
                      onClick={() => {
                        setShowPassword(!showPassword);
                      }}
                    >
                      {showPassword ? (
                        <ion-icon name="eye-outline"></ion-icon>
                      ) : (
                        <ion-icon name="eye-off-outline"></ion-icon>
                      )}
                    </button>
                  </div>
                  <label className="form-bottom-label">
                    <ion-icon name="alert-circle-outline"></ion-icon>
                    <p>Password must include 8-16 characters</p>
                  </label>
                </div>
                <div className="form-group">
                  <label className="form-label">Confirm Password</label>
                  <div className="input-group input-group--1">
                    <input
                      name="confirm_password"
                      type={showConfirmPassword ? "text" : "password"}
                      value={userLoginDetails.confirm_password}
                      placeholder="Confirm password"
                      autoComplete="new-password"
                      onChange={handleChangeUserInputs}
                    />
                    <button
                      type="button"
                      onClick={() => {
                        setShowConfirmPassword(!showConfirmPassword);
                      }}
                    >
                      {showConfirmPassword ? (
                        <ion-icon name="eye-outline"></ion-icon>
                      ) : (
                        <ion-icon name="eye-off-outline"></ion-icon>
                      )}
                    </button>
                  </div>
                  {userLoginDetails.password &&
                  userLoginDetails.confirm_password &&
                  userLoginDetails.password !==
                    userLoginDetails.confirm_password ? (
                    <label className="form-bottom-label">
                      <ion-icon name="alert-circle-outline"></ion-icon>
                      <p>Passwords do not match</p>
                    </label>
                  ) : null}
                </div>
                <div
                  className="form-group mb-medium"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: "1.6rem",
                  }}
                >
                  <label>OTP</label>
                  <div className="pin-input-group">
                    <PinGroup
                      type="text"
                      numInputs={6}
                      pin={userLoginDetails.otp}
                      handler={(otp) =>
                        setUserLoginDetails((uLD) => ({ ...uLD, otp }))
                      }
                    />
                  </div>
                </div>

                {message}
                {!hideBtn ? (
                  <button
                    ref={submitBtnRef}
                    className={`${
                      activateLogin ? "enabled" : "disabled"
                    } button-primary`}
                    type="submit"
                  >
                    Reset Password
                  </button>
                ) : null}
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default ResetPassword;
