const LimitedOffer = ({ cartBanners }) => {
  return (
    <div className="limited-part">
      <a
        href={`/categories?categories=${cartBanners?.middle_banner?.category_id}`}
      >
        <img
          src={cartBanners?.middle_banner?.img}
          alt=""
          className="limited-banner"
        />
      </a>
    </div>
  );
};

export default LimitedOffer;
